import 'react-multi-carousel/lib/styles.css'
import React, { useContext, useEffect, useRef, useState } from 'react'
import {
  SearchActivityHomeWidget,
  SearchHotelHomeWidget,
  autoSuggestHomeWidget,
} from '@/lib/searchHomeWidget'
import AuthContext from 'store/auth-context'
const Carousel = dynamic(() => import('react-multi-carousel'), {
  loading: () => <p>loading...</p>,
  ssr: false,
})
import Countrystyles from '../../../../../common/components/CountryDropdown/theme4/index.module.css'
import CustomLink from '@/components/Link'
import DesktopCalenderTheme4Css from '../../../../../common/components/widgets/flightSearchWidget/theme4/index.module.css'
import DesktopHotelStylesTheme4 from '../../../../../common/components/widgets/hotelSearchWidget/theme4/index.module.css'
import FlightSearchTheme5CSS from '../../../../../common/components/widgets/flightSearchWidget/theme5/index.module.css'
const FlightSearchWidget = dynamic(() =>
  import('../../../../../common/components/widgets/flightSearchWidget')
)
const HotelSearchWidget = dynamic(() =>
  import('../../../../../common/components/widgets/hotelSearchWidget')
)
import Image from 'next/image'
import b2bWidgetStyle from '../../../../../common/components/b2bwidgets/theme4/index.module.css'
import cf from '@/lib/utils/common'
import configUrl from '../../../../../config/config.json'
import parse from 'html-react-parser'
import styles from './index.module.css'
import { getAirlines } from '../../../../../common/lib/getAirlines'
import { getSupplierList } from '../../../../../common/lib/getSupplierList'
import getFareType from '../../../../../common/lib/getFareType'
import { useRouter } from 'next/router'
import MultiSelectDropDown from '../../../multiSelect/multiSelectDropDown'
import MultiSuppilerDropDownoption from '../../../../../common/components/multiDropdown/theme4'
import FareTypeDropDownoption from '../../../../../common/components/CheckboxDropdown/theme4'
import styleDerptfare from '../../../../../common/components/CheckboxDropdown/theme4/index.module.css'
import styleDerpt from '../../../../../common/components/multiDropdown/theme4/index.module.css'
import DesktopHotelStylesTheme2 from '../../../../../common/components/widgets/hotelSearchWidget/theme5/index.module.css'
const SearchWidgetActivityNew = dynamic(() =>
  import('../../../../../common/components/widgets/activityWidgetNew/index')
)
import ActivityTheme2cssfileNew from '../../../../../common/components/widgets/activityWidgetNew/theme5/index.module.css'
import DesktopCalenderThemeOneCss from '../../../../../common/components/widgets/flightSearchWidget/theme1/index.module.css'
import ActivityCalendarCss from '../../../../../common/components/widgets/activityWidget/theme5/index.module.css'
import PopupMobCss from '../../../../../common/components/ModalPopupComponent/theme5mobile/index.module.css'
const SearchWidgetActivity = dynamic(() =>
  import('../../../../../common/components/widgets/activityWidget/index')
)
import ActivityTheme2cssfile from '../../../../../common/components/widgets/activityWidget/theme5/index.module.css'
import Link from 'next/link'

// for mobile section ====>

import ActivityMobileSearchWidget from '../../../../../common/components/widgets/activityWidgetMobile'
import ActivitySearchMobileThemeOneCSS from '../../../../../common/components/widgets/activityWidgetMobile/theme2/index.module.css'
import ExperienceMobileSearchWidget from '../../../../../common/components/widgets/experienceWidgetMobile'
import FlightMobileSearchWidget from '../../../../../common/components/widgets/flightSearchWidget'
import FlightSearchMobileThemeOneCSS from '../../../../../common/components/widgets/flightSearchWidget/theme5/index.module.css'
import Head from 'next/head'
import HotelMobileSearchWidget from '../../../../../common/components/widgets/hotelMobileSearchWidget'
import HotelSearchMobileThemeOneCSS from '../../../../../common/components/widgets/hotelMobileSearchWidget/theme2/index.module.css'
import HolidayMobileSearchWidget from '../../../../../common/components/widgets/holidayWidgetMobile/index'
import HolidayMobileCss from '../../../../../common/components/widgets/holidayWidgetMobile/theme5/index.module.css'
import PopupCss from '../../../../../common/components/ModalPopupComponent/theme5mobile/index.module.css'
// import Image from 'next/image'
import ModalPopupComponent from '../../../../../common/components/ModalPopupComponent/theme2/ModalPopupComponent'
// import cf from '@/lib/utils/common'
// import configUrl from '../../../../../config/config.json'
// import { getHomePageData } from '../../../../../common/lib/Homepage'
// import { getSiteData } from '../../../../../common/lib/auth/authhelpers'
import modalCss from '../../../../../common/components/ModalPopupComponent/theme2/index.module.css'
// import parse from 'html-react-parser'
import swPopupCss from '../../../../../common/components/widgets/commonCss/swPopupCssTheme2.module.css'
// import styles from './index.module.css'
import {
  myGreetingMySwiper,
  myGreetingMySwiper2,
  myGreetingElevatenew,
  myGreetingCruiseHolidays,
  myGreetingChild_featureSwiper,
  myGreetingTestimonial_data,
  myGreetingMyclientSwiper,
  myGreetingOffermain,
  myGreetingNewlyadded,
  myGreetingMostbooked,
  myGreetingHoneymoonpkg,
  myGreetingExploremain,
  myGreetingBudgetSwiper,
} from '../../../../public/js/carousalFun'
import dynamic from 'next/dynamic'
import MoreMenu from '../../../../../common/components/Moremenu'
import MoremenuCss from '../../../../../common/components/header/theme5/index.module.css'
import TabHeader from '../../../../../common/components/tabHeader/index'

const availableSearchTypes = [
  'flight_search',
  'hotel_search',
  'activity_search',
  'experience_search',
  'holiday_search',
  'others_search',
]
const times = ['Before 6 AM', '6 AM - 12 PM', '12 PM - 6 PM', 'After 6 PM']
const rbdClass = [
  { label: 'All', value: '*', isChecked: false },
  { label: 'A', value: 'A', isChecked: false },
  { label: 'B', value: 'B', isChecked: false },
  { label: 'C', value: 'C', isChecked: false },
  { label: 'D', value: 'D', isChecked: false },
  { label: 'E', value: 'E', isChecked: false },
  { label: 'F', value: 'F', isChecked: false },
  { label: 'G', value: 'G', isChecked: false },
  { label: 'H', value: 'H', isChecked: false },
  { label: 'I', value: 'I', isChecked: false },
  { label: 'J', value: 'J', isChecked: false },
  { label: 'K', value: 'K', isChecked: false },
  { label: 'L', value: 'L', isChecked: false },
  { label: 'M', value: 'M', isChecked: false },
  { label: 'N', value: 'N', isChecked: false },
  { label: 'O', value: 'O', isChecked: false },
  { label: 'P', value: 'P', isChecked: false },
  { label: 'Q', value: 'Q', isChecked: false },
  { label: 'R', value: 'R', isChecked: false },
  { label: 'S', value: 'S', isChecked: false },
  { label: 'T', value: 'T', isChecked: false },
  { label: 'U', value: 'U', isChecked: false },
  { label: 'V', value: 'V', isChecked: false },
  { label: 'W', value: 'W', isChecked: false },
  { label: 'X', value: 'X', isChecked: false },
  { label: 'Y', value: 'Y', isChecked: false },
  { label: 'Z', value: 'Z', isChecked: false },
]

const DepartureTimeOptions = [
  { label: 'All', value: '', isChecked: true },
  { label: 'Before 6 AM', value: '00000559', isChecked: false },
  { label: '6 AM - 12 PM', value: '06001159', isChecked: false },
  { label: '12 PM - 6 PM', value: '12001759', isChecked: false },
  { label: 'After 6 PM', value: '18002359', isChecked: false },
]
export default function Theme2Flow(props) {
  const [initFlightData, setInitFlightData] = useState([])
  const [getDepartureSuggestionList, setDepartureSuggestionList] = useState([])
  const [getGoingToSuggestionList, setGoingToSuggestionList] = useState([])
  const [activeItemIndex, setActiveItemIndex] = useState(0)
  const wrapperRef = useRef(null)
  const setMoreWrapperRef = useRef(null)
  const [expandHotelNameBoolean, setExpandHotelName] = useState(false)
  const [expandActivityName, setExpandActivityName] = useState(false)
  const [getHotelAutoSuggestList, setHotelAutoSuggestList] = useState([])
  const [getActivityAutoSuggestList, setActivityAutoSuggestList] = useState([])
  const [initHotelData, setInitHotelData] = useState([])
  const [homeBodyDesktop, setHomeBodyDesktop] = useState([])
  const [homeBodyMobile, setHomeBodyMobile] = useState([])
  const [hotelDefultDays, setHotelDefultDays] = useState(0)
  const [userProfile, setUserProfile] = useState('')
  const [indexOfWidgetToshow, setIndexOfWidget] = useState(1)
  const [widClicked, setWidClicked] = useState(false)
  const [airlineList, setAirlineList] = useState([])
  const [supplierList, setSupplierList] = useState([])
  const [selectedAirlines, setSelectedAirlines] = useState([])
  // const [selectedSupplier, setSelectedSupplier] = useState([])
  const [selectedDepartureTime, setSelectedDepartureTime] = useState([])
  const [selectedRbdClass, setSelectedRbdClass] = useState([])
  const [SelectedClassValue, setSelectedClassValue] = useState({ label: 'Economy', id: 1 })
  const [SerachWidget, setSerachWidget] = useState('holiday')
  const [SerachWidMob, setSerachWidMob] = useState('holiday')
  const ctx = useContext(AuthContext)
  const flightWidRef = useRef(null)
  const hotelWidRef = useRef(null)
  const [rbdOptions, setrbdOptions] = useState(rbdClass)
  const [rbdOptionsOne, setrbdOptionsOne] = useState(rbdClass)
  const [rbdOptionsTwo, setrbdOptionsTwo] = useState(rbdClass)
  const [SelectFareType, setSelectFareType] = useState([])
  const [DepartureTime, setDepartureTime] = useState(DepartureTimeOptions)
  const [DepartureTimeOne, setDepartureTimeOne] = useState(DepartureTimeOptions)
  const [DepartureTimeTwo, setDepartureTimeTwo] = useState(DepartureTimeOptions)
  const [menuData, setMenuData] = useState('')
  const [isPopup, setPopup] = useState(false)
  const [isMobWid, setIsMobWid] = useState(false)
  const [isActive, setIsActive] = useState('holidays' || 'holiday')
  const [getrecentSearchList, setrecentSearchList] = useState([])
  const [openMorePopup, setopenMorePopup] = useState(false)

  const box = useRef(null)

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      // slidesToSlide: 3 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  }

  useEffect(() => {
    getFareType().then((res) => {
      if (res && res.data && res.data.length > 0) {
        let fareType = res.data.map((ele, id) => {
          return {
            label: ele.pax_type,
            value: ele.pax_type,
            isChecked: false,
            code: ele.pax_code,
            //  displayValue: ele
          }
        })
        setSelectFareType(fareType)
      }
    })
    getSupplierList().then((res) => {
      if (res && res.status && res.data && res.data.data && res.data.data.length > 0) {
        let supplier = res.data.data.map((ele, id) => {
          return {
            label: ele.id,
            value: ele.id,
            isChecked: false,
            //  displayValue: ele
          }
        })
        supplier.unshift({ label: 'All', value: '*', isChecked: false, displayValue: 'All' })
        setSupplierList(supplier)
      }
    })
    getAirlines().then((res) => {
      if (res && res.status && res.data && res.data.data && res.data.data.length > 0) {
        let airline = res.data.data.map((ele, id) => {
          return {
            label: ele.airline_name,
            value: ele.airline_code,
            isChecked: false,
            displayValue: ele,
          }
        })
        airline.unshift({ label: 'All', value: '*', isChecked: false, displayValue: 'All' })

        setAirlineList(airline)
      }
    })

    let userToken = cf.getCookieTocken('userToken')
    cf.getUserProfile(userToken, props.site_id).then((res) => {
      if (res.status === true) {
        // console.log('ctx', ctx)
        setUserProfile(ctx?.user)
      } else {
        setUserProfile(res)
      }
    })
    console.log('props.home.data.data.body', props.home.data.data.body)

    if (props?.home?.data?.data?.body?.desktop) {
      setHomeBodyDesktop(props.home.data.data.body.desktop)

      /**FIND FIRST AVILLABLE WIDGET**/
      let indexOfFirstWidghts = props.home.data.data.body.desktop?.findIndex((x) =>
        availableSearchTypes.includes(x.type)
      )
      setIndexOfWidget(indexOfFirstWidghts)

      let hotelIndex = props.home.data.data.body.desktop.findIndex(
        (items) => items.type === 'hotel_search'
      )
      if (hotelIndex > -1) {
        setHotelDefultDays(props.home.data.data.body.desktop[hotelIndex].default_days)
      }
    }
    if (props?.home?.data?.data?.body?.mobile) setHomeBodyMobile(props.home.data.data.body.mobile)
    const style = document.createElement('style')
    if (
      !cf.isValueEmpty(props) &&
      !cf.isValueEmpty(props.home) &&
      !cf.isValueEmpty(props.home.data)
    ) {
      style.innerHTML = props.home.data.data.css
      document.head.appendChild(style)
    }
    setActiveItemIndex(activeItemIndex)
    if (!cf.isValueEmpty(props) && !cf.isValueEmpty(props.home) && props.home.status === true) {
      if (
        !cf.isValueEmpty(props.home.data.data) &&
        !cf.isValueEmpty(props.home.data.data.body) &&
        !cf.isValueEmpty(props.home.data.data.body.desktop) &&
        props.home.data.data.body.desktop.length > 0
      ) {
        let index = props.home.data.data.body.desktop.findIndex(
          (items) => items.type === 'flight_search'
        )
        let hotelIndex = props.home.data.data.body.desktop.findIndex(
          (items) => items.type === 'hotel_search'
        )
        // console.log('🚀 ~ file: index.js:249 ~ useEffect ~ props?.home?:', props?.home)

        if (index !== -1) {
          if (
            !cf.isValueEmpty(props.home.data.data.body.desktop[index].airports) &&
            props.home.data.data.body.desktop[index].airports.length > 0
          ) {
            // let sortedDefaultAirports = props?.home?.data?.data?.body?.desktop &&
            let sortedDefaultAirports = []
            if (
              props?.home?.data?.data?.body?.desktop &&
              props?.home?.data?.data?.body?.desktop[index] &&
              props?.home?.data?.data?.body?.desktop[index].airports &&
              props?.home?.data?.data?.body?.desktop[index].airports.length
            ) {
              sortedDefaultAirports = props?.home?.data?.data?.body?.desktop[index].airports.sort(
                (a, b) => {
                  return a.ORDER - b.ORDER
                }
              )
            }
            setInitFlightData([...sortedDefaultAirports])
            setDepartureSuggestionList([...sortedDefaultAirports])
            setGoingToSuggestionList([...sortedDefaultAirports])
          }
        }
        if (hotelIndex !== -1) {
          if (
            !cf.isValueEmpty(props.home.data.data.body.desktop[hotelIndex].locations) &&
            props.home.data.data.body.desktop[hotelIndex].locations.length > 0
          ) {
            setInitHotelData([...props.home.data.data.body.desktop[hotelIndex].locations])
            setHotelAutoSuggestList([...props.home.data.data.body.desktop[hotelIndex].locations])
          }
        }
      }
    }
    // showMore()
    // <script>
    //    {showMore()}
    // </script>

    // console.log('ctx?.isLoggedIn', ctx?.user)
    if (ctx?.isLoggedIn == true) {
      // setTimeout( document.getElementById("signupContainer").style.display = "none", 5000);
      setTimeout(() => {
        document?.getElementById('signupContainer')?.style.display = 'none'
      }, 5000)
    }

    document.addEventListener('click', handleClickOutside, false)
    return () => {
      document.removeEventListener('click', handleClickOutside, false)
    }
  }, [])

  const getAutoSuggestDataFunc = (value, section, type, event) => {
    switch (section) {
      case 'flight':
        if (event === 'click') {
          if (value.length === 0) {
            if (type === 'departure') {
              setDepartureSuggestionList([...initFlightData])
            } else {
              setGoingToSuggestionList([...initFlightData])
            }
          }
        } else {
          console.log('hereeeeee11111')

          autoSuggestHomeWidget({ autoSuggest: value }).then((res) => {
            if (!cf.isValueEmpty(res) && res.status === true && !cf.isValueEmpty(res.data)) {
              if (type === 'departure') {
                if (!res.data.length && value.length < 2) {
                  setDepartureSuggestionList([...initFlightData])
                } else {
                  setDepartureSuggestionList([...res.data])
                }
              } else {
                if (!res.data.length && value.length < 2) {
                  setGoingToSuggestionList([...initFlightData])
                } else {
                  setGoingToSuggestionList([...res.data])
                }
              }
            }
          })
        }
        break
      case 'hotel':
        if (value.length > 0) {
          SearchHotelHomeWidget({ autoSuggest: value }).then((res) => {
            // console.log('res.data===>', res)
            if (!cf.isValueEmpty(res) && res.status === true && !cf.isValueEmpty(res.data)) {
              if (res.data.status === 'success' && !cf.isValueEmpty(res.data.result)) {
                // console.log('res.data', res.data)
                setHotelAutoSuggestList([...res.data.result.records])
                setrecentSearchList([...res.data.result.recent_search])
                setExpandHotelName(true)
              }
            }
          })
        } else {
          setHotelAutoSuggestList([...initHotelData])
          setExpandHotelName(true)
        }
        break
      case 'activity':
        SearchActivityHomeWidget({ autoSuggest: value }).then((res) => {
          if (!cf.isValueEmpty(res) && !cf.isValueEmpty(res.data) && res.data.status === true) {
            if (
              !cf.isValueEmpty(res.data.searchBycity) &&
              res.data.searchBycity !== 'Not Found' &&
              res.data.searchBycity.status === 'success'
            ) {
              if (
                !cf.isValueEmpty(res.data.searchBycity.result) &&
                res.data.searchBycity.result.length > 0
              ) {
                getActivityAutoSuggestList.cityList = [...res.data.searchBycity.result]
                //     setActivityAutoSuggestList({ ...getActivityAutoSuggestList })
                setActivityAutoSuggestList([...res.data.searchBycity.result])
                if (expandActivityName === false) {
                  setExpandActivityName(true)
                }
              }
              if (
                !cf.isValueEmpty(res.data.searchBycountry) &&
                res.data.searchBycountry !== 'Not Found' &&
                res.data.searchBycountry.status === 'success'
              ) {
                if (
                  !cf.isValueEmpty(res.data.searchBycountry.result) &&
                  res.data.searchBycountry.result.length > 0
                ) {
                  getActivityAutoSuggestList.countryList = [...res.data.searchBycountry.result]
                  //      setActivityAutoSuggestList({ ...getActivityAutoSuggestList })
                  setActivityAutoSuggestList([
                    ...getActivityAutoSuggestList.cityList,
                    ...getActivityAutoSuggestList.countryList,
                  ])

                  if (expandActivityName === false) {
                    setExpandActivityName(true)
                  }
                }
              }
            }
          }
        })
        break
      default:
        break
    }
  }
  const handleClickOutside = (event) => {
    // console.log('eventevent', event)
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      if (expandHotelNameBoolean === true) setExpandHotelName(false)
    }
    if (flightWidRef.current && !flightWidRef.current.contains(event.target)) {
      setWidClicked(false)
    }
    if (hotelWidRef.current && !hotelWidRef.current.contains(event.target)) {
      setWidClicked(false)
    }
    if (setMoreWrapperRef.current && !setMoreWrapperRef.current.contains(event.target)) {
      setopenMorePopup(false)
    }
  }

  const imageLoader = ({ src }) => {
    return `${'/shared/api/media/' + props.site_id + '/' + src}`
  }

  const onClickWidgets = () => {
    setWidClicked(true)
  }

  const multiSelectDropdown = () => {
    airlineList.forEach((option) => {
      option.displayValue = option.airline_name
    })
    return (
      <MultiSelectDropDown
        inputPlaceHolder={'Select Airline'}
        dropdownInputPlaceHolder={'Type here Airline'}
        options={airlineList}
        setoptions={setAirlineList}
      />
    )
  }

  const multiSelectSupplierDropdown = () => {
    supplierList.forEach((option) => {
      option.displayValue = option.name
    })

    return (
      <MultiSelectDropDown
        inputPlaceHolder={'Select Supplier'}
        dropdownInputPlaceHolder={'Type here Supplier'}
        options={supplierList}
        setoptions={setSupplierList}
      />
    )
  }

  const selectFareTypeDropdown = () => {
    return (
      <FareTypeDropDownoption
        options={SelectFareType}
        setoptions={setSelectFareType}
        inputPlaceHolder={'Select Fare Type'}
        style={styleDerptfare}
        box={box}
      />
    )
  }
  const multiSelectDepartureTimeDropdown = () => {
    return (
      <MultiSuppilerDropDownoption
        options={DepartureTime}
        setoptions={setDepartureTime}
        inputPlaceHolder={'Departure Time'}
        style={styleDerpt}
        box={box}
      />
    )
  }

  const multiSelectDepartureTimeDropdownOne = () => {
    return (
      <MultiSuppilerDropDownoption
        options={DepartureTimeOne}
        setoptions={setDepartureTimeOne}
        inputPlaceHolder={'Departure Time'}
        style={styleDerpt}
        box={box}
      />
    )
  }

  const multiSelectDepartureTimeDropdownTwo = () => {
    return (
      <MultiSuppilerDropDownoption
        options={DepartureTimeTwo}
        setoptions={setDepartureTimeTwo}
        inputPlaceHolder={'Departure Time'}
        style={styleDerpt}
        box={box}
      />
    )
  }

  const multiSelectRBDClassDropdown = () => {
    return (
      <MultiSelectDropDown
        inputPlaceHolder={'Select RBD Class'}
        dropdownInputPlaceHolder={'Type here RBD'}
        options={rbdOptions}
        setoptions={setrbdOptions}
        // SelectedClassValue={SelectedClassValue}
        setSelectedClassValue={setSelectedClassValue}
      />
    )
  }

  const multiSelectRBDClassDropdownOne = () => {
    return (
      <MultiSelectDropDown
        inputPlaceHolder={'Select RBD Class'}
        dropdownInputPlaceHolder={'Type here RBD'}
        options={rbdOptionsOne}
        setoptions={setrbdOptionsOne}
      />
    )
  }

  const multiSelectRBDClassDropdownTwo = () => {
    return (
      <MultiSelectDropDown
        inputPlaceHolder={'Select RBD Class'}
        dropdownInputPlaceHolder={'Type here RBD'}
        options={rbdOptionsTwo}
        setoptions={setrbdOptionsTwo}
      />
    )
  }
  const multiDepartureTimeDropDown = () => {
    ;<MultiSuppilerDropDownoption
      options={DepartureTime}
      setoptions={setDepartureTime}
      inputPlaceHolder={'Departure Time'}
      style={styleDerpt}
      box={box}
    />
  }
  const onChangeClassValue = (element) => {
    setSelectedClassValue({
      label: element.label,
      id: element.id,
    })
    setSelectedRbdClass([])
  }

  const router = useRouter()
  const query = router.query.index

  useEffect(() => {
    try {
      if (props?.home?.data?.data?.body?.desktop) {
        setHomeBodyDesktop(props.home.data.data.body.desktop)
      }

      if (props?.home?.data?.data?.body?.mobile) {
        setHomeBodyMobile(props.home.data.data.body.mobile)
      }

      if (
        window.location.pathname == '/' ||
        window.location.pathname == '/holidays' ||
        window.location.pathname == '/holiday'
      ) {
        setSerachWidget('holiday')
        setSerachWidMob('holiday')
        setIsActive('holidays')
      }
      if (window.location.pathname == '/hotel' || window.location.pathname == '/hotels') {
        setSerachWidget('hotel')
        setSerachWidMob('hotel')
        setIsActive('hotel')
      }
      if (window.location.pathname == '/flight' || window.location.pathname == '/flights') {
        setSerachWidget('flight')
        setSerachWidMob('flight')
        setIsActive('flight')
      }

      if (window.location.pathname == '/activity' || window.location.pathname == '/activities') {
        setSerachWidget('activity')
        setSerachWidMob('activity')
        setIsActive('activity')
      }

      if (window.location.pathname == '/experiences') {
        setSerachWidget('experience')
      }

      setTimeout(() => {
        myGreetingMySwiper(),
          myGreetingMySwiper2(),
          myGreetingElevatenew(),
          myGreetingCruiseHolidays(),
          myGreetingChild_featureSwiper(),
          myGreetingTestimonial_data(),
          myGreetingMyclientSwiper(),
          myGreetingOffermain(),
          myGreetingNewlyadded(),
          myGreetingMostbooked(),
          myGreetingHoneymoonpkg(),
          myGreetingExploremain(),
          myGreetingBudgetSwiper()
      }, 100)
    } catch (error) {
      console.log(error)
    }

    // console.log("query==>",props?.home?.data?.data);
  }, [query])

  // useEffect(() => {
  //   if (window.location.pathname == '/') {
  //     // setSerachWidget('holiday')
  //     setSerachWidMob('holiday')
  //   }
  //   if (window.location.pathname == '/hotel' ) {
  //     // setSerachWidget('hotel')
  //     setSerachWidMob('hotel')
  //   }
  //   if (window.location.pathname == '/flights' || '/flight') {
  //     // setSerachWidget('flight')
  //     setSerachWidMob('flight')
  //   }
  //   if (window.location.pathname == '/experiences') {
  //     setSerachWidget('experience')
  //   }
  // }, [isMobWid])

  // for mobile section ============>

  const onClickHeaderMenu = (data, i) => {
    setIsMobWid(true)

    if (data.code == 'holidays' || 'holiday') {
      setSerachWidMob('holiday')
      setIsActive('holidays')
      window.location.href = data.url
    }
    if (data.code == 'flight') {
      setSerachWidMob('flight')
      setIsActive('flight')
      window.location.href = data.url
    }
    if (data.code == 'hotel') {
      setSerachWidMob('hotel')
      setIsActive('hotel')
      window.location.href = data.url
    }
    if (data.code == 'activity') {
      setSerachWidMob('activity')
      setIsActive('activity')
      window.location.href = data.url
    }
    if (data.code == 'others') {
      window.location.href = data.url
      return
    }
    setMenuData(data.code)
    setPopup(true)
  }

  const renderInfo = (menudata) => {
    menudata = menudata == 'holiday' ? 'holidays' : menudata
    switch (menudata) {
      case 'flight':
        return (
          <div className="renderinfo-flight">
            <ModalPopupComponent
              isMobile={true}
              isPopup={isPopup}
              onClosePopup={() => {
                setPopup(false)
              }}
              modalCss={swPopupCss}
            >
              {/* {this.renderLoginPopup(this.state.popupType)} */}
              <FlightMobileSearchWidget
                pageTheme={props?.home?.data?.site?.site_theme}
                isSRP={false}
                onClosePopup={(bool) => {
                  setPopup(bool)
                }}
                homeData={!cf.isValueEmpty(initFlightData) ? initFlightData : []}
                styles={FlightSearchMobileThemeOneCSS}
                calendarStyles={DesktopCalenderThemeOneCss}
                getAutoSuggestData={(value, type, event) =>
                  getAutoSuggestDataFunc(value, 'flight', type, event)
                }
                getGoingToSuggestionList={getGoingToSuggestionList}
                getDepartureSuggestionList={getDepartureSuggestionList}
              />
            </ModalPopupComponent>
          </div>
        )
      case 'hotel':
        return (
          <div className="renderinfo-flight">
            <ModalPopupComponent
              isMobile={true}
              isPopup={isPopup}
              onClosePopup={() => {
                setPopup(false)
              }}
              modalCss={swPopupCss}
              showCrossButton={false}
            >
              {/* <HotelMobileSearchWidget
                pageTheme={props?.home?.data?.site?.site_theme}
                isSRP={false}
                styles={HotelSearchMobileThemeOneCSS}
                getAutoSuggestValue={(value) => getAutoSuggestDataFunc(value, 'hotel')}
                expandHotelNameBoolean={expandHotelNameBoolean}
                getAutoSuggestList={getHotelAutoSuggestList}
                expandHotelNameBooleanfunc={(value) => setExpandHotelName(value)}
                homeData={!cf.isValueEmpty(initHotelData) ? initHotelData : []}
                onClosePopup={() => setPopup(false)}
              /> */}

              <div>
                <HotelSearchWidget
                  pageTheme={props?.home?.data?.site?.site_theme}
                  isSRP={false}
                  isOffline={false}
                  styles={DesktopHotelStylesTheme2}
                  b2bStyles={b2bWidgetStyle}
                  getAutoSuggestValue={(value) => getAutoSuggestDataFunc(value, 'hotel')}
                  expandHotelNameBoolean={expandHotelNameBoolean}
                  getDefaultDays={hotelDefultDays}
                  getAutoSuggestList={getHotelAutoSuggestList}
                  getRecentSearchList={getrecentSearchList}
                  expandHotelNameBooleanfunc={(value) => setExpandHotelName(value)}
                  homeData={!cf.isValueEmpty(initHotelData) ? initHotelData : []}
                  dateFormat={props?.home?.data?.site?.date_format}
                  userProfile={userProfile}
                  Countrystyles={Countrystyles}
                />
              </div>
            </ModalPopupComponent>
          </div>
        )
      case 'holidays':
        return (
          <div className="renderinfo-flight">
            <ModalPopupComponent
              isMobile={true}
              isPopup={isPopup}
              onClosePopup={() => {
                setPopup(false)
              }}
              modalCss={modalCss}
            >
              <HolidayMobileSearchWidget
                //  {...props}
                //  cssfile={cssfile}
                cssfile={HolidayMobileCss}
                pageTheme={props?.home?.data?.site?.site_theme}
                calendarStyles={DesktopCalenderThemeOneCss}
                dateFormat={''}
                //  b2bStyles={b2bWidgetStyle}
                popupcss={PopupCss}
                isSrp={false}
              />
            </ModalPopupComponent>
          </div>
        )
      case 'activity':
        return (
          <div className="renderinfo-flight">
            <ModalPopupComponent
              isMobile={true}
              showCrossButton={false}
              isPopup={isPopup}
              onClosePopup={() => {
                setPopup(false)
              }}
              modalCss={swPopupCss}
            >
              <ActivityMobileSearchWidget
                pageTheme={props?.home?.data?.site?.site_theme}
                isSRP={false}
                cssfileMobile={ActivitySearchMobileThemeOneCSS}
                getAutoSuggestValue={(value) => getAutoSuggestDataFunc(value, 'activity')}
                expandActivityNameBoolean={expandActivityName}
                getAutoSuggestList={getActivityAutoSuggestList}
                expandActivityBooleanfunc={(value) => setExpandActivityName(value)}
                homeData={!cf.isValueEmpty(initHotelData) ? initHotelData : []}
                onClosePopup={() => setPopup(false)}
                dateFormat={props?.home?.data?.site?.date_format || 'DD-MMM-YYYY'}
              />
            </ModalPopupComponent>
          </div>
        )
      case 'experience':
        return (
          <div className="renderinfo-flight">
            <ModalPopupComponent
              isMobile={true}
              showCrossButton={false}
              isPopup={isPopup}
              onClosePopup={() => {
                setPopup(false)
              }}
              modalCss={swPopupCss}
            >
              <ExperienceMobileSearchWidget
                pageTheme={props?.home?.data?.site?.site_theme}
                isSRP={false}
                cssfileMobile={ActivitySearchMobileThemeOneCSS}
                getAutoSuggestValue={(value) => getAutoSuggestDataFunc(value, 'activity')}
                expandActivityNameBoolean={expandActivityName}
                getAutoSuggestList={getActivityAutoSuggestList}
                expandActivityBooleanfunc={(value) => setExpandActivityName(value)}
                homeData={!cf.isValueEmpty(initHotelData) ? initHotelData : []}
                onClosePopup={() => setPopup(false)}
                dateFormat={props?.home?.data?.site?.date_format}
              />
            </ModalPopupComponent>
          </div>
        )
    }
  }

  const copyCouponCode = (id) => {
    if (id) {
      var copyText = document.getElementById(id).innerHTML
      if (copyText) {
        navigator.clipboard.writeText(copyText)
        var copycouponcodeId = 'copycouponcode_' + id
        document.getElementById(copycouponcodeId).innerHTML = 'Copied'
        setTimeout(function () {
          document.getElementById(copycouponcodeId).innerHTML = 'Copy Code'
        }, 3000)
      }
    }
  }

  const setMorePopupHandler = () => {
    setopenMorePopup(!openMorePopup)
  }

  return (
    <>
      {props?.isMobile ? (
        // For Mobile section =============>
        <>
          <>
            <div>
              <div className={styles.all_section}>
                {props?.home?.data?.data?.header?.mobile?.menu?.map((link, i) => {
                  // console.log('link, i', link, i)
                  return (
                    <>
                      {link.status == 'Active' && (
                        <div key={'headerMenu' + i} className={styles.section_div}>
                          {link?.text?.toLowerCase() == 'explore saudi' ? (
                            <div
                              className={styles.main_tab}
                              onClick={() => {
                                window.location.href = link.url
                              }}
                            >
                              <div className={styles.service_icon}>
                                <img
                                  src={`${'/shared/api/media/' + props.site_id + '/' + link.icon}`}
                                  alt=""
                                />
                              </div>
                              <label> {link.text}</label>
                            </div>
                          ) : link?.code !== 'others' ? (
                            <Link href={link.url}>
                              <div
                                className={styles.main_tab}
                                // onClick={() => onClickHeaderMenu(link, i)}
                              >
                                <div
                                  className={`${styles.service_icon} ${
                                    isActive == link.code ? styles.active_service : ''
                                  }`}
                                >
                                  <img
                                    src={`${
                                      '/shared/api/media/' + props.site_id + '/' + link.icon
                                    }`}
                                    alt=""
                                  />
                                </div>
                                <label
                                  className={`${isActive == link.code ? styles.active_label : ''}`}
                                >
                                  {' '}
                                  {link.text}
                                </label>
                                {/* <Link
                            key={link.text}
                            href={link.url}
                            onClick={() => { window.location.href = link.url }}
                            className=""
                        >
                            {link.text}
                            <img src={`${ '/shared/api/media/' + "6124e1368ede4f08bf2d9ad4" + '/' + link.icon}`} alt="" />
                        </Link> */}
                              </div>
                            </Link>
                          ) : (
                            <a
                              href={
                                localStorage.getItem('appType') == 'mobileapp' &&
                                props?.isMobile &&
                                (link.text == 'Forex' ||
                                  link.text == 'Visa' ||
                                  link.text == 'Vouchers')
                                  ? '/mobileAppLink?page=' + link.text + '&value=' + link.url
                                  : link.url
                              }
                              target="_blank"
                            >
                              <div
                                className={styles.main_tab}
                                // onClick={() => onClickHeaderMenu(link, i)}
                              >
                                <div
                                  className={`${styles.service_icon} ${
                                    isActive == link.code ? styles.active_service : ''
                                  }`}
                                >
                                  <img
                                    src={`${
                                      '/shared/api/media/' + props.site_id + '/' + link.icon
                                    }`}
                                    alt=""
                                  />
                                </div>
                                <label
                                  className={`${isActive == link.code ? styles.active_label : ''}`}
                                >
                                  {' '}
                                  {link.text}
                                </label>
                                {/* <Link
                            key={link.text}
                            href={link.url}
                            onClick={() => { window.location.href = link.url }}
                            className=""
                        >
                            {link.text}
                            <img src={`${ '/shared/api/media/' + "6124e1368ede4f08bf2d9ad4" + '/' + link.icon}`} alt="" />
                        </Link> */}
                              </div>
                            </a>
                          )}
                        </div>
                      )}
                    </>
                  )
                })}

                {/* {menuData && isPopup && renderInfo(menuData)} */}
              </div>
              <>
                {SerachWidMob == 'holiday' && (
                  <div>
                    <HolidayMobileSearchWidget
                      //  {...props}
                      //  cssfile={cssfile}
                      cssfile={HolidayMobileCss}
                      pageTheme={props?.home?.data?.site?.site_theme}
                      calendarStyles={DesktopCalenderThemeOneCss}
                      dateFormat={''}
                      //  b2bStyles={b2bWidgetStyle}
                      popupcss={PopupCss}
                      isSrp={false}
                    />
                  </div>
                )}

                {SerachWidMob == 'flight' && (
                  <div>
                    <FlightSearchWidget
                      // pageTheme={props?.home?.data?.site?.site_theme}
                      pageTheme="theme5"
                      isSRP={false}
                      isOffline={false}
                      homeData={!cf.isValueEmpty(initFlightData) ? initFlightData : []}
                      styles={FlightSearchTheme5CSS}
                      b2bStyles={b2bWidgetStyle}
                      calendarStyles={DesktopCalenderTheme4Css}
                      getAutoSuggestData={(value, type, event) =>
                        getAutoSuggestDataFunc(value, 'flight', type, event)
                      }
                      getGoingToSuggestionList={getGoingToSuggestionList}
                      getDepartureSuggestionList={getDepartureSuggestionList}
                      dateFormat={props?.home?.data?.site?.date_format}
                      userProfile={userProfile}
                      multiSelectDropdown={multiSelectDropdown}
                      multiSelectSupplierDropdown={multiSelectSupplierDropdown}
                      selectFareTypeDropdown={selectFareTypeDropdown}
                      selectedAirlines={airlineList}
                      selectedSupplier={supplierList}
                      selectedDepartureTime={selectedDepartureTime}
                      selectedRbdClass={rbdOptions}
                      selectedRbdClassOne={rbdOptionsOne}
                      selectedRbdClassTwo={rbdOptionsTwo}
                      SelectedClassValue={SelectedClassValue}
                      DepartureTime={DepartureTime}
                      FareType={SelectFareType}
                      multiDepartureTimeDropDown={multiDepartureTimeDropDown}
                      isMobile={props?.isMobile}
                      setSelectFareType={setSelectFareType}
                      multicityFlag={
                        props.home?.data?.data?.body?.mobile.filter(
                          (ele, id) => ele.type == 'flight_search'
                        )?.[0]?.multicity_required || false
                      }
                    />
                  </div>
                )}

                {SerachWidMob == 'hotel' && (
                  <div>
                    <HotelSearchWidget
                      pageTheme={props?.home?.data?.site?.site_theme}
                      isSRP={false}
                      isOffline={false}
                      styles={DesktopHotelStylesTheme2}
                      b2bStyles={b2bWidgetStyle}
                      getAutoSuggestValue={(value) => getAutoSuggestDataFunc(value, 'hotel')}
                      expandHotelNameBoolean={expandHotelNameBoolean}
                      getDefaultDays={hotelDefultDays}
                      getAutoSuggestList={getHotelAutoSuggestList}
                      getRecentSearchList={getrecentSearchList}
                      expandHotelNameBooleanfunc={(value) => setExpandHotelName(value)}
                      homeData={!cf.isValueEmpty(initHotelData) ? initHotelData : []}
                      dateFormat={props?.home?.data?.site?.date_format}
                      userProfile={userProfile}
                      Countrystyles={Countrystyles}
                      PopupMobCss={PopupMobCss}
                      isMobile={true}
                    />
                  </div>
                )}
                {SerachWidMob == 'activity' && (
                  <div>
                    <SearchWidgetActivity
                      {...props}
                      //  cssfile={cssfile}
                      cssfile={ActivityTheme2cssfile}
                      pageTheme={props?.home?.data?.site?.site_theme}
                      calendarStyles={ActivityCalendarCss}
                      dateFormat={props?.home?.data?.site?.date_format}
                      b2bStyles={b2bWidgetStyle}
                      isMobile={props?.isMobile}
                      popupcss={PopupCss}
                      isSrp={false}
                    />
                  </div>
                )}
              </>
            </div>

            {/* HTML Body Section =======>     */}

            <div className="main_body">
              {homeBodyMobile.map((res, i) => {
                // console.log("🚀 ~ file: index.js:1015 ~ {homeBodyMobile.map ~ res:", res)
                return (
                  <div key={'homeBodyMbl' + i}>
                    {!cf.isValueEmpty(res.type) && res.type == 'image_banner' && (
                      <div className="img_banner_home">
                        <Image loader={imageLoader} src={res.banners} width={500} layout="fill" />
                        {/* <img src={ '/shared/api/media/' + props.site_id + '/' + res.banners} /> */}
                      </div>
                    )}
                    <div>
                      {!cf.isValueEmpty(res.html)
                        ? parse(res.html, {
                            replace: ({ attribs }) => {
                              if (attribs && attribs.copycouponcode === 'copy') {
                                return (
                                  <p
                                    class="copyZth"
                                    copycouponcode="copy"
                                    copycouponcodevalue={attribs.copycouponcodevalue}
                                    copycouponcodenumber={attribs.copycouponcodenumber}
                                    id={attribs.id}
                                    onClick={() => copyCouponCode(attribs.copycouponcodenumber)}
                                  >
                                    Copy Code
                                  </p>
                                )
                              }
                            },
                          })
                        : // <Interweave content={res.html} />
                          ''}
                    </div>
                    {/* {!cf.isValueEmpty(res.type) && res.type == 'image_carousal' && (
                    <div>
                      <Carousel
                        swipeable={false}
                        draggable={false}
                        showDots={true}
                        responsive={responsive}
                        ssr={false} 
                        infinite={true}
                        autoPlay={props.isMobile ? true : true}
                        autoPlaySpeed={3000}
                        keyBoardControl={true}
                        customTransition="all .5"
                        transitionDuration={500}
                        containerClass="carousel-container"
                        dotListClass="custom-dot-list-style"
                        itemClass="carousel-item-padding-40-px"
                      >
                        {!cf.isValueEmpty(res) &&
                          !cf.isValueEmpty(res.banners) &&
                          res.banners.length > 0 &&
                          res.banners.map((imagedata, index) => {
                            return (
                              <div key={'banner' + index} className="img_banner_home">
                                <CustomLink href={imagedata.banner_url}>
                                  <Image loader={imageLoader} src={imagedata.r_image} width={500} />
                                </CustomLink>
                              </div>
                            )
                          })}
                      </Carousel>
                    </div>
                  )} */}
                  </div>
                )
              })}
            </div>
          </>
        </>
      ) : (
        // for desktop Section =============>
        <>
          {/* carousal slider  starts */}

          {/* <div className={styles.img_banner_home_thtwo}>
                  <img  className={styles.styleBanner} src='/theme2/logn_bg.png' />
             </div> */}
          <div className={styles.home_container}>
            <div style={{ margin: 0 }}>
              {homeBodyDesktop.map((res, i) => {
                // console.log("🚀 ~ file: index.js:1097 ~ {homeBodyDesktop.map ~ res:", res)
                return (
                  <div key={'homeBodyDsktp' + i}>
                    {!cf.isValueEmpty(res.type) && res.type == 'image_carousal' && (
                      <div style={{ margin: 0 }}>
                        <Carousel
                          swipeable={false}
                          draggable={false}
                          showDots={false}
                          responsive={responsive}
                          ssr={false} // means to render carousel on server-side.
                          infinite={true}
                          autoPlay={props.isMobile ? false : false}
                          autoPlaySpeed={3000}
                          keyBoardControl={true}
                          customTransition="all .5"
                          transitionDuration={500}
                          containerClass={styles.carouse_container2}
                          //  containerClass="carousel-container"
                          // deviceType={this.props.deviceType}
                          dotListClass="custom-dot-list-style"
                          itemClass="carousel-item-padding-40-px"
                        >
                          {!cf.isValueEmpty(res) &&
                            !cf.isValueEmpty(res.banners) &&
                            res.banners.length > 0 &&
                            res.banners.map((imagedata, index) => {
                              return (
                                //  <div>index====={index}</div>
                                //  <img style={{ height: 300, background: '#EEE' }} src={`${ '/shared/api/media/'+props.site_id+'/' + imagedata.image}`} />
                                <div
                                  style={{ margin: 0 }}
                                  key={'banner' + index}
                                  className={styles.img_banner_home2}
                                >
                                  <CustomLink href={imagedata.banner_url}>
                                    <Image
                                      loader={imageLoader}
                                      src={imagedata?.image}
                                      width={500}
                                      layout="fill"
                                      alt="image-carousel"
                                    />
                                  </CustomLink>
                                </div>
                              )
                            })}
                        </Carousel>
                      </div>
                    )}
                  </div>
                )
              })}
            </div>
            {/* carousal slider end */}
            {/* tab navbar starts */}
            <div className={styles.widget_parent}>
              <div
                className={`${styles.widget_container} ${
                  SerachWidget === 'flight'
                    ? styles.flight_widget
                    : SerachWidget === 'hotel'
                    ? styles.hotel_widget
                    : SerachWidget === 'activity'
                    ? styles.activity_widget
                    : styles.holiday_widget
                }`}
              >
                <div className="container mx-auto offiletab xl:px-8">
                  <div className={styles.search}>
                    <div className={styles.holidaymaintabnavbarrow}>
                      <div className={styles.holidaycarouselcontainer}>
                        <nav className={styles.navbartab}>
                          {/* {<TabHeader 
                          menu={props?.home?.data?.data?.header?.menu} 
                          additionalClassName={styles.main_sub_header} 
                          showIcon={true} 
                          query={query}
                          siteData={props?.home?.data || ''}
                          cssfile={styles}
                          {...props}  
                        />} */}

                          <ul>
                            {props?.home?.data?.data?.header?.menu
                              .filter((ele) => ele.status.toLowerCase() == 'active')
                              .slice(0, 7)
                              .map((res, i) => {
                                return (
                                  <>
                                    {res.code.includes('flight') && (
                                      <Link href={res.url}>
                                        <li
                                          className={
                                            SerachWidget === 'flight'
                                              ? `${styles.activetab}`
                                              : `${styles.inactive}`
                                          }
                                          onClick={() => {
                                            // window.open(res.url, '_self')
                                            setSerachWidget('flight')
                                          }}
                                        >
                                          <div className={styles.service_icon}>
                                            {SerachWidget === 'flight' ? (
                                              <img src="theme5/flight-active.svg" alt="flight" />
                                            ) : res.icon == '' ? (
                                              <img src="theme5/forexImg.svg" alt="others" />
                                            ) : (
                                              <img
                                                src={`${
                                                  '/shared/api/media/' +
                                                  props.site_id +
                                                  '/' +
                                                  res.icon
                                                }`}
                                                alt="hotel"
                                              />
                                            )}
                                          </div>
                                          <span className={styles.service_label}>{res.text}</span>
                                        </li>
                                      </Link>
                                    )}

                                    {res.code.includes('hotel') && (
                                      <Link href={res.url}>
                                        <li
                                          className={
                                            SerachWidget === 'hotel'
                                              ? `${styles.activetab}`
                                              : `${styles.inactive}`
                                          }
                                          onClick={() => {
                                            // window.open(res.url, '_self')
                                            setSerachWidget('hotel')
                                          }}
                                        >
                                          <div className={styles.service_icon}>
                                            {SerachWidget === 'hotel' ? (
                                              <img src="theme5/hotel-active.svg" alt="hotel" />
                                            ) : res.icon == '' ? (
                                              <img src="theme5/forexImg.svg" alt="others" />
                                            ) : (
                                              <img
                                                src={`${
                                                  '/shared/api/media/' +
                                                  props.site_id +
                                                  '/' +
                                                  res.icon
                                                }`}
                                                alt="hotel"
                                              />
                                            )}
                                          </div>
                                          {res.text}
                                        </li>
                                      </Link>
                                    )}

                                    {res.code.includes('activity') && (
                                      <Link href={res.url}>
                                        <li
                                          className={
                                            SerachWidget == 'activity'
                                              ? `${styles.activetab}`
                                              : `${styles.inactive}`
                                          }
                                          onClick={() => {
                                            // window.open(res.url, '_self')
                                            setSerachWidget('activity')
                                          }}
                                        >
                                          <div className={styles.service_icon}>
                                            {SerachWidget === 'activity' ? (
                                              <img
                                                src="theme5/activity-active.svg"
                                                alt="activity"
                                              />
                                            ) : res.icon == '' ? (
                                              <img src="theme5/forexImg.svg" alt="others" />
                                            ) : (
                                              <img
                                                src={`${
                                                  '/shared/api/media/' +
                                                  props.site_id +
                                                  '/' +
                                                  res.icon
                                                }`}
                                                alt="hotel"
                                              />
                                            )}
                                          </div>
                                          {res.text}
                                        </li>
                                      </Link>
                                    )}
                                    {res.code.includes('holiday') && (
                                      <Link href={res.url}>
                                        <li
                                          className={
                                            SerachWidget == 'holiday'
                                              ? `${styles.activetab}`
                                              : `${styles.inactive}`
                                          }
                                          onClick={() => {
                                            setSerachWidget('holiday')
                                            // window.open(res.url, '_self')
                                          }}
                                        >
                                          <div className={styles.service_icon}>
                                            {SerachWidget === 'holiday' ? (
                                              <img src="theme5/holiday-active.svg" alt="holiday" />
                                            ) : (
                                              <img
                                                src={`${
                                                  '/shared/api/media/' +
                                                  props.site_id +
                                                  '/' +
                                                  res.icon
                                                }`}
                                                alt="holiday"
                                              />
                                            )}
                                          </div>
                                          {res.text}
                                        </li>
                                      </Link>
                                    )}
                                    {res.code.includes('other') && (
                                      <li
                                        className={
                                          SerachWidget == 'others_search'
                                            ? `${styles.activetab}`
                                            : `${styles.inactive}`
                                        }
                                        onClick={() => {
                                          // setSerachWidget('others_search')
                                          window.open(res.url, '_blank')
                                        }}
                                      >
                                        <div className={styles.service_icon}>
                                          {res.icon == '' ? (
                                            <img src="theme5/forexImg.svg" alt="others" />
                                          ) : (
                                            <img
                                              src={`${
                                                '/shared/api/media/' +
                                                props.site_id +
                                                '/' +
                                                res.icon
                                              }`}
                                              alt="others"
                                            />
                                          )}
                                        </div>
                                        {res.text}
                                      </li>
                                    )}
                                  </>
                                )
                              })}

                            {props?.home?.data?.data?.header?.menu?.filter(
                              (ele) => ele.status.toLowerCase() == 'active'
                            ).length > 7 && (
                              <MoreMenu
                                menuItems={props?.home?.data?.data?.header?.menu.slice(7)}
                                styles={MoremenuCss}
                                setMoreWrapperRef={setMoreWrapperRef}
                                state={openMorePopup}
                                setMorePopupHandler={setMorePopupHandler}
                                site_id={props?.data?.data?.siteData?.site.id}
                                showIcon={true}
                              />
                            )}
                          </ul>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
                {/* tab navbar end */}
                {/* widgets components starts */}

                <div>
                  {homeBodyDesktop.map((res, i) => {
                    return (
                      <div key={'homeBodyDsktp' + i}>
                        {/* {!cf.isValueEmpty(res.type) && res.type == 'image_banner' && (
                                    <div className="img_banner_home">
                                      <Image loader={imageLoader} src={res.banners} width={500} />
                                    </div>
                                  )} */}
                        {SerachWidget === 'flight' && res.type == 'flight_search' && (
                          <div>
                            <FlightSearchWidget
                              // pageTheme={props?.home?.data?.site?.site_theme}
                              pageTheme="theme5"
                              isSRP={false}
                              isOffline={false}
                              homeData={!cf.isValueEmpty(initFlightData) ? initFlightData : []}
                              styles={FlightSearchTheme5CSS}
                              b2bStyles={b2bWidgetStyle}
                              calendarStyles={DesktopCalenderTheme4Css}
                              getAutoSuggestData={(value, type, event) =>
                                getAutoSuggestDataFunc(value, 'flight', type, event)
                              }
                              getGoingToSuggestionList={getGoingToSuggestionList}
                              getDepartureSuggestionList={getDepartureSuggestionList}
                              dateFormat={props?.home?.data?.site?.date_format}
                              userProfile={userProfile}
                              multiSelectDropdown={multiSelectDropdown}
                              multiSelectSupplierDropdown={multiSelectSupplierDropdown}
                              selectFareTypeDropdown={selectFareTypeDropdown}
                              multiSelectDepartureTimeDropdown={multiSelectDepartureTimeDropdown}
                              multiSelectRBDClassDropdown={multiSelectRBDClassDropdown}
                              multiSelectRBDClassDropdownTwo={multiSelectRBDClassDropdownTwo}
                              multiSelectRBDClassDropdownOne={multiSelectRBDClassDropdownOne}
                              selectedAirlines={airlineList}
                              selectedSupplier={supplierList}
                              selectedDepartureTime={selectedDepartureTime}
                              selectedRbdClass={rbdOptions}
                              selectedRbdClassOne={rbdOptionsOne}
                              selectedRbdClassTwo={rbdOptionsTwo}
                              SelectedClassValue={SelectedClassValue}
                              DepartureTime={DepartureTime}
                              FareType={SelectFareType}
                              multiDepartureTimeDropDown={multiDepartureTimeDropDown}
                              multiSelectDepartureTimeDropdownOne={
                                multiSelectDepartureTimeDropdownOne
                              }
                              multiSelectDepartureTimeDropdownTwo={
                                multiSelectDepartureTimeDropdownTwo
                              }
                              // setrbdOptions={setrbdOptions}
                              // setrbdOptionsOne={setrbdOptionsOne}
                              // setrbdOptionsTwo={setrbdOptionsTwo}
                              // DepartureTimeOne={DepartureTimeOne}
                              // DepartureTimeTwo={DepartureTimeTwo}
                              setSelectFareType={setSelectFareType}
                              multicityFlag={res.multicity_required}
                            />
                          </div>
                        )}

                        {SerachWidget === 'hotel' && res.type == 'hotel_search' && (
                          <div>
                            <HotelSearchWidget
                              pageTheme={props?.home?.data?.site?.site_theme}
                              isSRP={false}
                              isOffline={false}
                              styles={DesktopHotelStylesTheme2}
                              b2bStyles={b2bWidgetStyle}
                              getAutoSuggestValue={(value) =>
                                getAutoSuggestDataFunc(value, 'hotel')
                              }
                              expandHotelNameBoolean={expandHotelNameBoolean}
                              getDefaultDays={hotelDefultDays}
                              getAutoSuggestList={getHotelAutoSuggestList}
                              getRecentSearchList={getrecentSearchList}
                              expandHotelNameBooleanfunc={(value) => setExpandHotelName(value)}
                              homeData={!cf.isValueEmpty(initHotelData) ? initHotelData : []}
                              dateFormat={props?.home?.data?.site?.date_format}
                              userProfile={userProfile}
                              Countrystyles={Countrystyles}
                              PopupMobCss={PopupMobCss}
                            />
                          </div>
                        )}

                        {SerachWidget == 'activity' && res.type == 'activity_search' && (
                          <SearchWidgetActivity
                            {...props}
                            //  cssfile={cssfile}
                            cssfile={ActivityTheme2cssfile}
                            pageTheme={props?.home?.data?.site?.site_theme}
                            calendarStyles={ActivityCalendarCss}
                            dateFormat={props?.home?.data?.site?.date_format}
                            b2bStyles={b2bWidgetStyle}
                            isMobile={props?.isMobile}
                            popupcss={PopupCss}
                            isSrp={false}
                          />
                        )}

                        {/* {SerachWidget == 'experience' && res.type == 'experience_search' && (
                      <SearchWidgetExperience
                        {...props}
                        cssfile={ActivityTheme2cssfile}
                        pageTheme={props?.home?.data?.site?.site_theme}
                        calendarStyles={DesktopCalenderThemeOneCss}
                        dateFormat={props?.home?.data?.site?.date_format}
                        b2bStyles={b2bWidgetStyle}
                        userProfile={userProfile}
                      />
                    )} */}

                        {SerachWidget === 'holiday' && res.type == 'holiday_search' && (
                          <SearchWidgetActivityNew
                            {...props}
                            //  cssfile={cssfile}
                            cssfile={ActivityTheme2cssfileNew}
                            pageTheme={props?.home?.data?.site?.site_theme}
                            calendarStyles={DesktopCalenderThemeOneCss}
                            dateFormat={props?.home?.data?.site?.date_format}
                            b2bStyles={b2bWidgetStyle}
                          />
                        )}
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>

            {/* widgets components starts */}
            {/* cards & other data starts */}
            {/* <Theme2Cards 
                  props={props}
                  /> */}
            <div>
              {/* {   console.log("🚀 ~ file: index.js:609 ~ {homeBodyDesktop.map ~ homeBodyDesktop:", homeBodyDesktop)} */}
              {homeBodyDesktop.map((res, i) => {
                // console.log("🚀 ~ file: index.js:596 ~ {homeBodyDesktop.map ~ res:", res)
                return (
                  <div key={'homeBodyDsktp' + i}>
                    <div>
                      {!cf.isValueEmpty(res.html)
                        ? parse(res.html, {
                            replace: ({ attribs }) => {
                              if (attribs && attribs.copycouponcode === 'copy') {
                                return (
                                  <p
                                    class="copyZth"
                                    copycouponcode="copy"
                                    copycouponcodevalue={attribs.copycouponcodevalue}
                                    copycouponcodenumber={attribs.copycouponcodenumber}
                                    id={attribs.id}
                                    onClick={() => copyCouponCode(attribs.copycouponcodenumber)}
                                  >
                                    Copy Code
                                  </p>
                                )
                              }
                            },
                          })
                        : // <Interweave content={res.html} />
                          ''}
                    </div>
                  </div>
                )
              })}
            </div>
          </div>

          {/* cards & other data  end */}
        </>
      )}
    </>
  )
}
