import React, { Component, createRef } from "react";

export default class CheckboxDropdown4Mobile extends Component {
  constructor(props) {
    super(props);
    // this.box = createRef();

    this.state = {
      inputPlaceHolder: this.props.inputPlaceHolder,
      inputValue: "",
      dropdownVisible: false,
      reRender: false,
    };
  }

  setDropdownWrapperRef = (node) => {
    this.dropdownWrapperRef = node;
  };

  componentDidMount() {
    console.log("Et@12345", this.props);

    this.getInputValue();

    // Adding click event listener
    document.addEventListener("mousedown", this.handleDropDownClickOutside);
  }

  componentWillReceiveProps(nextProps) {
    console.log("nextProps", nextProps);
    // this.getInputValue()
    this.setState({
      reRender: !this.state.reRender,
    });
  }

  getInputValue = (e) => {
    let tempOption = JSON.parse(JSON.stringify(this.props.options));
    let checkOption = tempOption.find((ele) => ele.isChecked == true);
    console.log("advcbnm", checkOption);
    if (checkOption) {
      this.setState({ inputValue: checkOption.value });
    }
  };

  // Function for click event
  handleDropDownClickOutside = (e) => {
    if (
      this.dropdownWrapperRef &&
      !this.dropdownWrapperRef.contains(e.target)
    ) {
      this.setState({ dropdownVisible: false });
    }
  };
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleDropDownClickOutside);
  }
  selectionHandler(value) {
    console.log("valfghjkue", value);
    let tempOption = JSON.parse(JSON.stringify(this.props.options));
    tempOption.forEach((element) => {
      if (element.label === value) {
        element.isChecked = !element.isChecked;
      } else {
        element.isChecked = false;
      }
    });
    this.props.setoptions(tempOption);

    let temp = tempOption.find((el) => el.isChecked === true);

    console.log("temp", temp);
    console.log("hereee3333");
    this.props.setoptions(tempOption);
    this.setState(
      {
        inputValue: temp ? temp.value : "",
      },
      () => {
        console.log(
          "multiicity advcbnm",
          this.state.inputValue,
          temp,
          tempOption
        );
      }
    );
  }

  render() {
    return (
      <div className={this.props.style.drop_wd}>
        <div ref={this.setDropdownWrapperRef}>
          <span className={this.props.style.dropdown_Flx}>
            <input
              type="text"
              placeholder={this.props.inputPlaceHolder}
              value={this.state.inputValue || ""}
              onClick={() => {
                this.setState({ dropdownVisible: !this.state.dropdownVisible });
              }}
            />
            {/* <img
                            src="/arrow-down-wid.svg"
                            alt=""
                        /> */}
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="8">
              <path
                fill-rule="evenodd"
                fill="#01445F"
                d="M11.606.458c.704-.008 1.103.082 1.515.021a1.073 1.073 0 0 1-.021 1.517L7.746 7.198l-.747-.769.748.772a1.072 1.072 0 0 1-1.554-.061L.942 1.996A1.074 1.074 0 0 1 .921.479C1.334.54 2.12.45 2.437.458L7.2 4.931 11.606.458z"
              ></path>
            </svg>
          </span>

          {this.state.dropdownVisible ? (
            <div className={this.props.style.dropDownlistmain}>
              <ul className={this.props.style.dropdown_value_container}>
                {this.props.options?.map((option) => {
                  return (
                    <li>
                      <label className={this.props.style.checkbox_label}>
                        <input
                          type="radio"
                          className={this.props.style.pointer}
                          id={option.label}
                          checked={option?.isChecked ? true : false}
                          onClick={(e) => {
                            this.selectionHandler(option.label);
                          }}
                        />
                        {/* <span
                                                        className={this.props.style.bagBox}
                                                    >
                                                        <span
                                                            className={this.props.style.bagCheck}
                                                        ></span>
                                                    </span> */}

                        <span className={this.props.style.advTitle}>
                          {option.label}
                        </span>
                      </label>
                    </li>
                  );
                })}
              </ul>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}
