import React, { Component } from "react";
// import { add, format } from "date-fns";

// import B2BWidgets from "../../../b2bwidgets/index";
// import DesktopCalender from "../../../calendar/DesktopCalender";
import autoSuggest from "../../../../lib/holidayautosuggest";
import categoryList from "../../../../lib/categoryList";
import cf from "../../../../lib/utils/common";
import ModalPopupComponent from "@/commonComponents/ModalPopupComponent/theme5mobile/ModalPopupComponent";
import { getStorage, setStorage } from "../../../../lib/utils/localStorage";

// static 5 cities
const popularCities = [
  {
    destination_id: "11663",
    search_text: "Mumbai",
    location_type: 2,
  },
  {
    destination_id: "11667",
    search_text: "New Delhi",
    location_type: 2,
  },
  {
    destination_id: "11634",
    search_text: "Chennai",
    location_type: 2,
  },
  {
    destination_id: "12633",
    search_text: "Dubai",
    location_type: 2,
  },
  {
    destination_id: "10516",
    search_text: "Paris",
    location_type: 2,
  },
];

const debounce = (func, wait) => {
  let timeout;
  return (...args) => {
    const context = this;
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(context, args), wait);
  };
};
const fetchData = async (query, callBackFunc) => {
  if (query.length > 2) {
    const res = await autoSuggest({ searchText: query });
    callBackFunc(res);
  } else {
    callBackFunc(null);
  }
};
const debouncedFetchData = debounce((query, cb) => {
  fetchData(query, cb);
}, 400);

const categoryArray = [];

export default class Theme2 extends Component {
  constructor(props) {
    super(props);
    this.searchBoxRef = React.createRef();
    this.advanceSearchWrapper = React.createRef();
    this.guestDetailWrapper = React.createRef();
    // this.categoryRef = React.createRef();
    // this.monthRef = React.createRef();
    this.state = {
      activityName: new URLSearchParams(window.location.search).has("searchby")
        ? new URLSearchParams(window.location.search).get("searchby").toString()
        : "",
      isAutoSuggestVisible: false,
      isCategoryVisible: false,
      isMonthVisible: false,
      // checkInOutValue: "",
      // checkInValue: null,
      // checkOutValue: null,
      // isCalendarVisible: false,
      autoSuggestCountryData: [],
      autoSuggestCityData: popularCities,
      selectedAutoSuggestData: {
        category: new URLSearchParams(window.location.search).has("category")
          ? new URLSearchParams(window.location.search).get("category") !== ""
            ? new URLSearchParams(window.location.search)
                .get("category")
                .split(",")
                .map((ele) => categoryArray.push({ name: ele }))
            : []
          : [],
        name: new URLSearchParams(window.location.search).has("searchby")
          ? new URLSearchParams(window.location.search)
              .get("searchby")
              .toString()
          : "",
        type: new URLSearchParams(window.location.search).has("type")
          ? new URLSearchParams(window.location.search).get("type").toString()
          : "",
      },
      isError: false,
      isDateError: false,
      lastArrowAction: "",
      selectedAutoSuggestIndex: -1,
      noresults: false,
      categoryList: [],
      categoryValue: [],
      categoryOriginalArray: [],
      monthOriginalArray: cf.getMonthsRange(12),
      monthData: cf.getMonthsRange(12),
      monthValue: new URLSearchParams(window.location.search).has("month")
        ? new URLSearchParams(window.location.search).get("month").toString()
        : "",
      categorySearchValue: "",
      monthSearchValue: "",
      getrecentSearchList: null,
      getrecentSearchListLocal: null,
      search_key: "holidaySearchKey",
      search_key_all: "holidaySearchKeyAll",
      btnClicked: false,
    };
  }
  onClickAdvSearch = () => {
    if (!this.state.advSearch) {
      this.setState({ advSearch: true });
    } else {
      this.setState({ advSearch: false });
    }
  };
  componentDidMount() {
    // Local storage recent search ========>

    if (true) {
      let LocalSearch = getStorage(this.state.search_key);

      let LocalSearchAll = getStorage(this.state.search_key_all);

      if (LocalSearchAll != null) {
        this.setState({ getrecentSearchListLocal: JSON.parse(LocalSearchAll) });
      }
      if (LocalSearch != null && LocalSearchAll == null) {
        this.setState({ getrecentSearchListLocal: [JSON.parse(LocalSearch)] });
      }
    }
    try {
      categoryList().then((res) => {
        if (res?.status) {
          this.setState({
            categoryList: res.data,
            categoryOriginalArray: res.data,
          });
        } else {
          // Handle the 404 error here
          // For example, you can display an error message or redirect to a different page
          console.log("404 error occurred");
        }
      });
    } catch (e) {
      console.log(e);
    }

    if (new URLSearchParams(window.location.search).has("searchby")) {
      let text = new URLSearchParams(window.location.search)
        .get("searchby")
        .toString();
      if (text !== "") {
        autoSuggest({
          searchText: text,
        }).then((data) => {
          if (data?.data?.length > 0) {
            // console.log(data);
            this.setState({
              categoryList: data.data[0].category,
              monthData: data.data[0].month,
            });
          }
        });
      }
    }
    this.setState({ categoryValue: categoryArray });
    const activityname = new URLSearchParams(window.location.search).get(
      "activityname"
    );
    // const holidayName = new URLSearchParams(window.location.search).has('searchby')
    // this.setState({activityname: holidayName ? new URLSearchParams(window.location.search).get('searchby').toString() : ''})
    // this.onChangeCheckInOutValue(new Date());
    if (activityname != null) {
      debouncedFetchData(activityname, (data) => {
        if (
          data &&
          data.data
          // typeof data.data.searchBycity === "object"
        ) {
          const cityData = [...data.data];
          const selectedCity = cityData.filter((city) => {
            return city.name == activityname;
          })[0];
          this.setState({
            autoSuggestCityData: cityData,
            isAutoSuggestVisible: false,
            activityName: selectedCity.name,
            selectedAutoSuggestData: selectedCity,
            isError: false,
            noresults: false,
          });
        }
        if (data.status && data.data.length === 0) {
          this.setState({
            noresults: true,
            autoSuggestCityData: [],
            isAutoSuggestVisible: true,
          });
        }
      });
    }
    // document.addEventListener("mousedown", this.handleClickOutside);
  }
  // componentWillUnmount() {
  //   document.removeEventListener("mousedown", this.handleClickOutside);
  // }

  //Add client
  // adGuestId = (id) => {
  //   this.setState({ guest_id: id }, () => {
  //     if (this.state.guestError === true) {
  //       this.setState({ guestError: cf.isValueEmpty(id) ? true : false })
  //     }
  //     this.setState({ guest_id: id });
  //   });
  // }
  // rmGuestId = () => {
  //   this.setState({ guest_id: "" });
  // };
  // onChangeCheckInOutValue = (dateValue) => {
  //   console.log("date=value", dateValue);
  //   this.setState({
  //     checkInValue: cf.getDateWithFormat(
  //       // new Date(dateValue),
  //       add(new Date(dateValue), { days: 7 }),
  //       this?.props?.dateFormat
  //     ),
  //     checkOutValue: cf.getDateWithFormat(
  //       add(new Date(dateValue), { days: 8 }),
  //       this?.props?.dateFormat
  //     ),
  //     checkInOutValue: cf.getDateWithFormat(dateValue, this?.props?.dateFormat),
  //     isDateError: false,
  //   });
  // };

  onChanegeSetActivityName = (value) => {
    this.setState({ activityName: value, isError: false });
    if (value === "") {
      this.setState({
        autoSuggestCityData: [],
        // isAutoSuggestVisible: false,
        selectedAutoSuggestData: [],
      });
    } else {
      debouncedFetchData(value, (data) => {
        if (
          data &&
          data.data
          // data.data.searchBycity &&
          // typeof data.data.searchBycity === "object"
        ) {
          this.setState({
            autoSuggestCityData: [...data.data],
            isAutoSuggestVisible: true,
            noresults: false,
          });
        }
        if (data.status && data.data.length === 0) {
          this.setState({
            noresults: true,
            autoSuggestCityData: [],
            isAutoSuggestVisible: true,
          });
        }
      });
    }
  };

  onClickSearchActivity = () => {
    // Local storage recent search ========>

    // if (this.state.hotelName && this.props?.userProfile && this.props?.userProfile?.status == false ) {
    if (this.state.activityName) {
      let LocalSearch = getStorage(this.state.search_key);
      let LocalSearchAll = JSON.parse(getStorage(this.state.search_key_all));
      const searchItem = JSON.parse(LocalSearch);

      if (LocalSearch) {
        if (LocalSearchAll == null) {
          var searchData = getStorage(this.state.search_key);
          var searchDataArr = [JSON.parse(searchData)];

          let myObject = {
            searchby: this.state.activityName,
          };
          if (searchItem.searchby !== myObject.searchby) {
            searchDataArr.unshift(myObject);
            var searchDataStrig = JSON.stringify(searchDataArr);
            setStorage(this.state.search_key, searchDataStrig);
            setStorage(
              this.state.search_key_all,
              JSON.stringify(searchDataArr)
            );
          }
          // searchDataArr.unshift(myObject);
          // var searchDataStrig = JSON.stringify(searchDataArr)
          // setStorage(this.state.search_key, searchDataStrig)
          // setStorage(this.state.search_key_all, JSON.stringify(searchDataArr))
        }

        if (LocalSearchAll) {
          var searchData = getStorage(this.state.search_key);
          var searchDataAll = JSON.parse(getStorage(this.state.search_key_all));
          var searchDataArr = [JSON.parse(searchData)];
          var indexArr = [];
          searchDataAll.forEach((x, i) => {
            indexArr.push(x.searchby);
          });

          let myObject = {
            searchby: this.state.activityName,
          };
          if (!indexArr.includes(myObject.searchby)) {
            searchDataAll.unshift(myObject);
            var searchDataStrig = JSON.stringify(searchDataArr);
            setStorage(
              this.state.search_key_all,
              JSON.stringify(searchDataAll)
            );
          }
          if (searchDataAll.length > 5) {
            searchDataAll.pop();
            setStorage(
              this.state.search_key_all,
              JSON.stringify(searchDataAll)
            );
          }
        }
      } else {
        var searchData = [];
        let data = {
          searchby: this.state.activityName,
        };
        // searchData.push(data); // add the object to the array
        searchData.unshift(data);
        var searchDartaStrig = JSON.stringify(searchData);
        setStorage(this.state.search_key, searchDartaStrig);
      }

      let data = {
        searchby: this.state.activityName,
      };

      setStorage(this.state.search_key, JSON.stringify(data));
    }
    // if (
    //   !(
    //     this.state.selectedAutoSuggestData &&
    //     this.state.selectedAutoSuggestData.name
    //   )
    // ) {
    //   this.setState({ isError: true });
    // }
    // if (this.state.checkInOutValue == "") {
    //   this.setState({ isDateError: true });
    // }
    // if (this.props.isOffline === true && this.state.guest_id === '') {
    //   this.setState({ guestError: true })
    // } else {
    //   this.setState({ guestError: false })
    // }
    // if (
    //   this.state.selectedAutoSuggestData &&
    //   this.state.selectedAutoSuggestData.name
    //   // this.state.checkInOutValue
    // ) {
    let urlQuery = {
      searchby: this.state.activityName,
      type:
        this.state.selectedAutoSuggestData.hasOwnProperty("type") &&
        this.state.selectedAutoSuggestData?.type != ""
          ? this.state.selectedAutoSuggestData.type
          : this.state.monthValue != ""
          ? 1
          : "",
      category: this.state.categoryValue.map(({ name }) => `${name}`).join(","),
      month: this.state.monthValue,
      // checkin: format(new Date(this.state.checkInValue), "yyyy-MM-dd"),
      // checkout: format(new Date(this.state.checkOutValue), "yyyy-MM-dd"),
      // from: 1,
      // to: 30,
      // search_type: this.state.selectedAutoSuggestData.location_type,
      // search_text: this.state.selectedAutoSuggestData.destination_id,
      // currency: "AED",
      // rating: [...new Set(this.state.ratingSelected)].join(','),
      // guestId: this.state.guest_id
    };
    // console.log(urlQuery)
    this.setState({
      btnClicked: true,
    });
    cf.redirectTo("/holiday/search", urlQuery);
    // if (this.props.isOffline === true) {
    // if (this.state.guest_id !== '') {
    //   const searchParams = new URLSearchParams(urlQuery)
    //   const queryString = searchParams.toString()
    //   // window.location = "/offlinesearch/activity?" + queryString
    //   window.location =  "/holiday/search"
    // }
    // window.open(`${window.location.origin}` + "/offlinesearch/activity" , "_self");
    // cf.redirectTo("/offlinesearch/activity", urlQuery)

    // } else {
    // window.location =  "/holiday/search"
    // }
    // }
    // else {
    //   console.log('please select guest id')
    // }
  };
  // onClickSetActivityName = (element) => {
  //   this.setState({
  //     activityName: element.name,
  //     selectedAutoSuggestData: element,
  //     isError: false,
  //   });
  // };
  onInputFocus = (fieldName) => {
    if (fieldName == "autocomplete") {
      if (this.state.isAutoSuggestVisible === false) {
        this.setState({ isAutoSuggestVisible: true, isCalendarVisible: false });
      }
    } else if (fieldName == "search") {
      this.setState({ isAutoSuggestVisible: false, isCalendarVisible: false });
      document.querySelector("body").style.overflow = "auto";
    }
  };

  keyPress = (action) => {
    switch (action) {
      case "ArrowDown":
        // console.log("keyPress fn down called ", action);
        let children = document.getElementById(
          "ACTIVITY-AUTO-SUGGEST-DATA-LIST"
        ).children;

        let selectIndex = 0;
        selectIndex =
          this.state.selectedAutoSuggestIndex == children.length - 1
            ? 0
            : this.state.selectedAutoSuggestIndex + 1;
        // console.log("children of auto-suggest", children);
        for (let index = 0; index < children.length; index++) {
          let childElement = children[index];
          let id = childElement.id;
          if (id != null && id != "") {
            childElement.style.backgroundColor =
              selectIndex === index ? "#ccc" : "#fff";
          }
        }
        this.setState({
          selectedAutoSuggestIndex: selectIndex,
          lastArrowAction: "ArrowDown",
        });
        break;
      case "ArrowUp":
        console.log("keyPress fn up called ", action);
        let childrens = document.getElementById(
          "ACTIVITY-AUTO-SUGGEST-DATA-LIST"
        ).children;
        let selectIndexUp = 0;
        selectIndexUp =
          this.state.selectedAutoSuggestIndex == childrens.length - 1
            ? 0
            : this.state.selectedAutoSuggestIndex == -1
            ? 0
            : this.state.selectedAutoSuggestIndex - 1;
        console.log("children of auto-suggest", childrens);
        for (let index = 0; index < childrens.length; index++) {
          let childElement = childrens[index];
          let id = childElement.id;
          if (id != null && id != "") {
            childElement.style.backgroundColor =
              selectIndexUp === index ? "#ccc" : "#fff";
          }
        }
        // this.setState((prevState) => ({
        //   selectedAutoSuggestIndex:
        //     prevState.selectedAutoSuggestIndex > 0
        //       ? prevState.lastArrowAction=="ArrowDown"?prevState.selectedAutoSuggestIndex - 1:prevState.selectedAutoSuggestIndex
        //       : prevState.selectedAutoSuggestIndex+1,
        //   lastArrowAction: "ArrowUp",
        // }));
        this.setState({
          selectedAutoSuggestIndex: selectIndexUp,
          lastArrowAction: "ArrowUp",
        });
        break;
      case "Enter":
      case "Tab":
        let indexToSelect = 0;
        if (
          this.state.selectedAutoSuggestIndex != 0 &&
          this.state.lastArrowAction == "ArrowUp"
        ) {
          indexToSelect = this.state.selectedAutoSuggestIndex;
        } else if (
          this.state.selectedAutoSuggestIndex != 0 &&
          this.state.lastArrowAction == "ArrowDown"
        ) {
          indexToSelect = this.state.selectedAutoSuggestIndex;
        }
        // const indexToSelect = this.state.selectedAutoSuggestIndex===0?0:this.state.lastArrowAction?"ArrowUp"?this.state.selectedAutoSuggestIndex+1:this.state.selectedAutoSuggestIndex-1:0;
        const selectedItem = this.state.autoSuggestCityData[indexToSelect];
        this.setState({
          activityName: selectedItem.name,
          selectedAutoSuggestData: selectedItem,
          isError: false,
          isAutoSuggestVisible: false,
          selectedAutoSuggestIndex: indexToSelect,
          lastArrowAction: "",
        });
        document.querySelector("body").style.overflow = "auto";

        break;

      default:
        console.log("default", action);
        break;
    }
  };

  // scrollToMyRef = () => {
  //   this.searchBoxRef.current.scrollIntoView({
  //     behavior: "smooth",
  //     block: "center",
  //   });
  // };

  // onClickTraveller = () => {
  //   if (!this.state.openTraveler) {
  //     this.setState({ openTraveler: true })
  //   } else {
  //     this.setState({ openTraveler: false })
  //   }
  // }

  // handleStarCheck = (event) => {
  //   this.setState({ [event.target.name]: event.target.checked })
  // }

  // ratingHandler = (e) => {
  //   console.log('rating selected array =-->>', this.state.ratingSelected)
  //   const { value, checked } = e.target
  //   const { ratingSelected } = this.state

  //   if (checked) {
  //     this.setState({
  //       ratingSelected: [...ratingSelected, value]
  //     })
  //   } else {
  //     this.setState({
  //       ratingSelected: ratingSelected.filter(e => e !== value)
  //     })
  //   }
  // }

  // handleClickOutside = (e) => {
  //   if (
  //     this.advanceSearchWrapper.current &&
  //     !this.advanceSearchWrapper.current.contains(e.target)
  //   ) {
  //     this.setState({ advSearch: false });
  //   }
  //   if (
  //     this.guestDetailWrapper.current &&
  //     !this.guestDetailWrapper.current.contains(e.target)
  //   ) {
  //     this.setState({ openTraveler: false });
  //   }
  //   if (
  //     this.categoryRef.current &&
  //     !this.categoryRef.current.contains(e.target)
  //   ) {
  //     this.setState({ isCategoryVisible: false });
  //   }
  //   if (this.monthRef.current && !this.monthRef.current.contains(e.target)) {
  //     this.setState({ isMonthVisible: false });
  //   }
  // };

  categoryHandler = (e, ele) => {
    if (e.target.checked) {
      const isFound = this.state.categoryValue.some((element) => {
        if (element.name === ele.name) {
          return true;
        }
        return false;
      });
      if (!isFound) {
        this.setState({ categoryValue: [...this.state.categoryValue, ele] });
      }
    } else {
      this.setState({
        categoryValue: this.state.categoryValue.filter((value) => {
          return value.name !== ele.name;
        }),
      });
    }
  };

  categorySelected = (item) => {
    let value = false;
    this.state.categoryValue.map((ele) => {
      if (ele.name === item.name) {
        value = true;
      }
    });
    return value;
  };

  closeDestinationPopup = () => {
    this.setState({ isAutoSuggestVisible: false });
    document.querySelector("body").style.overflow = "auto";
  };
  closeCategoryPopup = () => {
    this.setState({ isCategoryVisible: false });
    document.querySelector("body").style.overflow = "auto";
  };
  closeMonthPopup = () => {
    this.setState({ isMonthVisible: false });
    document.querySelector("body").style.overflow = "auto";
  };

  onChangeCategorySearch = (e) => {
    this.setState({ categorySearchValue: e.target.value });
  };
  onChangeMonthSearch = (e) => {
    this.setState({ monthSearchValue: e.target.value });
  };

  render() {
    return (
      <div
        className={this.props.isSrp ? this.props.cssfile.holidayMain_srp : ""}
        // ref={this.searchBoxRef}
        // onClick={(e) => {
        //   e.stopPropagation();
        //   if (this.state.isAutoSuggestVisible) {
        //     this.setState({
        //       isAutoSuggestVisible: false,
        //     });
        //   }
        // }}
      >
        <div
          className={
            this.props.isSrp
              ? this.props.cssfile.activity_search_wid_container_srp
              : this.props.cssfile.activity_search_wid_container
          }
        >
          <div className="activity_search_wid_main">
            {/* {this.props?.sessionData?.type === 'B2B' &&

              <B2BWidgets {...this.state}
                styles={this.props.b2bStyles}
                type={'activity'}
                onClickTraveller={this.onClickTraveller}
                onClickAdvSearch={this.onClickAdvSearch}
                addGuestId={this.adGuestId}
                removeGuestId={this.rmGuestId}
                ratingHandler={this.ratingHandler}
                handleStarCheck={this.handleStarCheck}
                isOffline={this.props.isOffline}
                listWrapper={this.advanceSearchWrapper}
                guestWrapper={this.guestDetailWrapper}
                errorMessage={"Select Traveler"}
                isError={this.state.guestError}
              />} */}
            <div
              className={this.props.cssfile.activity_search_details_container}
            >
              <div className={this.props.cssfile.activity_search_details_main}>
                <div className={this.props.cssfile.activity_search_details_row}>
                  <div
                    className={
                      this.props.cssfile.activity_search_column +
                      " " +
                      this.props.cssfile.activity_search_name
                    }
                  >
                    <div
                      id="search-widget-main"
                      className={
                        this.props.cssfile.activity_search_wid_inputfield
                      }
                    >
                      <label>{"Destination"}</label>
                      {/* <span>
                      <svg
                        version="1.1"
                        id="Layer_1"
                        x="0px"
                        y="0px"
                        width="200px"
                        height="200px"
                        viewBox="0 0 200 200"
                      >
                        <defs></defs>
                        <g transform="matrix(1, 0, 0, 1, 5.362504, 3.483317)">
                          <path
                            fill="#266BAC"
                            d="M 137.761 105.745 L 79.925 163.779 C 76.558 166.948 71.407 166.948 68.238 163.779 C 65.07 160.61 65.07 155.262 68.238 152.092 L 120.33 100 L 68.238 47.909 C 65.07 44.542 65.07 39.392 68.238 36.223 C 71.407 33.054 76.557 33.054 79.925 36.223 L 137.761 94.059 C 140.93 97.426 140.93 102.576 137.761 105.745 Z"
                            style={{
                              transformBox: 'fill-box',
                              transformOrigin: '50% 50%',
                              transform: 'matrix(0, 1, -1, 0, 0.000003, 0)',
                            }}
                          ></path>
                        </g>
                      </svg>
                      </span> */}
                      <div
                        className={
                          this.props.cssfile.activity_search_wid_inputfield_main
                        }

                        // onClick={this.scrollToMyRef}
                      >
                        <input
                          type="text"
                          // readOnly
                          tabIndex={1}
                          placeholder={`Enter Destination eg. Goa...`}
                          autoComplete={"off"}
                          id="myTextField"
                          className={this.props.cssfile.search_widget_input_new}
                          value={
                            !cf.isValueEmpty(this.state.activityName) &&
                            !this.state.isAutoSuggestVisible
                              ? this.state.activityName
                              : ""
                          }
                          // autoFocus
                          onClick={(e) => {
                            // this.scrollToMyRef();
                            document.querySelector("body").style.overflow =
                              "hidden";
                            e.stopPropagation();
                            this.setState((prevState) => {
                              return {
                                ...prevState,
                                isAutoSuggestVisible:
                                  !prevState.isAutoSuggestVisible,
                                isCalendarVisible: false,
                                activityName: "",
                                autoSuggestCityData: [],
                                categoryList: this.state.categoryOriginalArray,
                                monthData: this.state.monthOriginalArray,
                                monthValue: "",
                                isCategoryVisible: false,
                                noresults: false,
                                categoryValue: [],
                                selectedAutoSuggestData: { name: "" },
                              };
                            });
                          }}
                          // onChange={(event) => {
                          //   event.stopPropagation();
                          //   this.onChanegeSetActivityName(event.target.value);
                          // }}

                          // onFocus={() => {
                          //   // e.stopPropagation();
                          //   // console.log("event on focus pressed", e.key);
                          //   this.onInputFocus("autocomplete");
                          // }}
                          // onKeyDown={(e) => {
                          //   e.stopPropagation();
                          //   this.keyPress(e.key);
                          // }}
                        />

                        {this.state.isError && (
                          <div className={this.props.cssfile.error}>
                            {"Enter Destination"}
                          </div>
                        )}

                        <ModalPopupComponent
                          isPopup={this.state.isAutoSuggestVisible}
                          modalCss={this.props.popupcss}
                          closePopup={this.closeDestinationPopup}
                          showBtn={false}
                          heading={"Destination"}
                        >
                          {/* {this.state.isAutoSuggestVisible === true ? ( */}
                          <div
                            className={
                              this.props.cssfile.activity_autocomplete_value
                            }
                            id="ACTIVITY-AUTO-SUGGEST-DATA-LIST"
                          >
                            <div
                              className={
                                this.props.cssfile.input_search_destination
                              }
                            >
                              <svg
                                className={this.props.cssfile.search_icon}
                                xmlns="http://www.w3.org/2000/svg"
                                width="15"
                                height="14"
                              >
                                <defs>
                                  <filter id="vz9xq22j6a">
                                    <feFlood
                                      flood-color="#979797"
                                      flood-opacity="1"
                                      result="floodOut"
                                    />
                                    <feComposite
                                      operator="atop"
                                      in="floodOut"
                                      in2="SourceGraphic"
                                      result="compOut"
                                    />
                                    <feBlend in="compOut" in2="SourceGraphic" />
                                  </filter>
                                </defs>
                                <g filter="url(#vz9xq22j6a)">
                                  <path
                                    fill-rule="evenodd"
                                    fill="#97144D"
                                    d="M10.928 9.513a6.02 6.02 0 1 0-9.812-6.982 6.02 6.02 0 0 0 8.397 8.397l2.83 2.83a1.003 1.003 0 0 0 1.415-1.415l-2.83-2.83zM2.006 6.031a4.015 4.015 0 1 1 6.855 2.84 4.015 4.015 0 0 1-6.855-2.84z"
                                  />
                                </g>
                              </svg>

                              <input
                                type="text"
                                tabIndex={1}
                                autoFocus
                                autoComplete={"off"}
                                id="myTextField"
                                placeholder={`Enter destination eg. Goa...`}
                                className={
                                  this.props.cssfile.search_widget_input_new
                                }
                                value={
                                  !cf.isValueEmpty(this.state.activityName)
                                    ? this.state.activityName
                                    : ""
                                }
                                onChange={(event) => {
                                  event.stopPropagation();
                                  this.onChanegeSetActivityName(
                                    event.target.value
                                  );
                                }}
                                onClick={(e) => {
                                  // this.scrollToMyRef();
                                  e.stopPropagation();
                                  this.setState((prevState) => {
                                    return {
                                      ...prevState,
                                      isAutoSuggestVisible: true,
                                      isCalendarVisible: false,
                                      activityName: "",
                                      selectedAutoSuggestData: { name: "" },
                                    };
                                  });
                                }}
                                // onFocus={() => {
                                //   // e.stopPropagation();
                                //   // console.log("event on focus pressed", e.key);
                                //   this.onInputFocus("autocomplete");
                                // }}
                                onKeyDown={(e) => {
                                  e.stopPropagation();
                                  this.keyPress(e.key);
                                }}
                              />
                            </div>
                            {/* <div className={this.props.cssfile.activity_search_new}>
                              <div className={this.props.cssfile.activity_iconsearch}>
                              <svg version="1.1"
                          id="Layer_1"
                          x="0px"
                          y="0px"
                          width="35px"
                          height="35px"
                          viewBox="0 0 35 35"
                          enableBackground="new 0 0 35 35">
                              <path id="Path_164" fill="#3479C5" d="M11.045,0.004C4.943,0.008-0.001,4.958,0.003,11.06S4.957,22.105,11.06,22.102
	c2.33,0,4.601-0.738,6.485-2.107l5.5,5.5c0.668,0.688,1.766,0.701,2.452,0.035c0.687-0.668,0.702-1.766,0.035-2.452
	c-0.013-0.013-0.023-0.022-0.035-0.035l-5.5-5.5c3.587-4.931,2.499-11.836-2.433-15.424C15.671,0.739,13.388-0.001,11.045,0.004z
	 M11.045,18.637c-4.193,0.006-7.596-3.389-7.602-7.582c-0.005-4.193,3.389-7.596,7.582-7.602c4.193-0.005,7.597,3.389,7.602,7.582
	c0.003,2.017-0.797,3.951-2.223,5.378c-1.422,1.421-3.349,2.22-5.359,2.22V18.637z"/>
                              </svg>
                              </div>
                              <div className={this.props.cssfile.activity_citysearch}>
                                <span>City / Destination / Activity</span>
                              </div>
                            </div>
                            <div className={this.props.cssfile.activity_recentsearch}>Recent Search</div> this will be picked later */}

                            {!cf.isValueEmpty(
                              this.state.autoSuggestCountryData
                            ) &&
                              this.state.autoSuggestCountryData.length > 0 &&
                              this.state.countryData.map((element, index) => {
                                return (
                                  <div
                                    className={
                                      this.props.cssfile.mainactivitydrop
                                    }
                                  >
                                    <div
                                      id={"COUNTRY-" + index}
                                      key={element.search_text}
                                      className={
                                        this.props.cssfile
                                          .activity_autocomplete_element
                                      }
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        this.setState({
                                          activityName: element.name,
                                          isAutoSuggestVisible: false,
                                        });
                                        document.querySelector(
                                          "body"
                                        ).style.overflow = "auto";

                                        // this.onClickSetActivityName(element);
                                      }}
                                    >
                                      <img src="images/location-01.svg" />
                                      <div>
                                        <div
                                          className={
                                            this.props.cssfile
                                              .activity_autocomplete_name
                                          }
                                        >
                                          {element.search_text}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            {!cf.isValueEmpty(
                              this.state.autoSuggestCityData
                            ) && (
                              <div className={this.props.cssfile.dropdown_main}>
                                {this.state.autoSuggestCityData.length > 0 && (
                                  <p className={this.props.cssfile.suggestions}>
                                    Suggestions
                                  </p>
                                )}
                                {this.state.autoSuggestCityData.length > 0 &&
                                  this.state.autoSuggestCityData.map(
                                    (element, index) => {
                                      return (
                                        <div
                                          id={"ACTIVITY_CITY-" + index}
                                          key={`city-${element.name}`}
                                          className={
                                            this.props.cssfile
                                              .activity_autocomplete_element
                                          }
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            this.setState({
                                              activityName: element.name,
                                              isAutoSuggestVisible: false,
                                              categoryList: element.category,
                                              monthData: element.month,
                                              selectedAutoSuggestData: element,
                                            });
                                            document.querySelector(
                                              "body"
                                            ).style.overflow = "auto";

                                            // this.onClickSetActivityName(element);
                                          }}
                                        >
                                          {/* <img src="images/location-01.svg" /> */}
                                          {/* <span></span> */}

                                          <div>
                                            <div
                                              className={
                                                this.props.cssfile
                                                  .activity_autocomplete_name
                                              }
                                            >
                                              {element.name}
                                            </div>
                                            {/* <div className={this.props.cssfile.activity_autocomplete_newname}>
                                        23 packages
                                      </div> this will be picked later */}
                                          </div>
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="17"
                                            height="22"
                                          >
                                            <path
                                              fill-rule="evenodd"
                                              d="m14.769 13.53-6.19 8.098c-.061.06-.152.12-.242.12a.276.276 0 0 1-.242-.12l-6.19-8.098a8.116 8.116 0 0 1 0-9.82C3.445 1.685 5.8.537 8.337.537c2.536 0 4.892 1.148 6.432 3.173a8.119 8.119 0 0 1 0 9.82zm-.483-9.458a7.482 7.482 0 0 0-5.949-2.931c-2.355 0-4.53 1.058-5.949 2.931a7.485 7.485 0 0 0 0 9.095l5.949 7.766 5.949-7.766a7.485 7.485 0 0 0 0-9.095zM8.337 13.47c-2.657 0-4.832-2.176-4.832-4.835S5.68 3.8 8.337 3.8s4.831 2.176 4.831 4.835-2.174 4.835-4.831 4.835zm0-9.066a4.242 4.242 0 0 0-4.228 4.231 4.242 4.242 0 0 0 4.228 4.23 4.242 4.242 0 0 0 4.228-4.23 4.242 4.242 0 0 0-4.228-4.231z"
                                            />
                                          </svg>
                                        </div>
                                      );
                                    }
                                  )}
                                {this.state.getrecentSearchListLocal?.length >
                                  0 && (
                                  <div
                                    className={
                                      this.props.cssfile.recent_container
                                    }
                                  >
                                    <div
                                      className={
                                        this.props.cssfile.recent_label
                                      }
                                    >
                                      Recent Search
                                    </div>
                                    {this.state.getrecentSearchListLocal.map(
                                      (el) => {
                                        return (
                                          <div
                                            className={
                                              this.props.cssfile
                                                .hotel_autocomplete_element
                                            }
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              console.log("name ==", el);
                                              this.setState({
                                                activityName: el.searchby,
                                                isAutoSuggestVisible: false,
                                                selectedAutoSuggestData: {
                                                  name: el.searchby,
                                                },
                                              });
                                              document.querySelector(
                                                "body"
                                              ).style.overflow = "auto";
                                            }}
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="19"
                                              height="18"
                                            >
                                              <path
                                                fillRule="evenodd"
                                                d="M9.969 3.402V8.83c0 .124.05.243.138.33l3.863 3.836a.465.465 0 0 1-.33.795.466.466 0 0 1-.333-.137L9.445 9.818a1.393 1.393 0 0 1-.412-.988V3.402c0-.256.21-.464.468-.464.259 0 .468.208.468.464zm.747 14.331a8.652 8.652 0 0 1-4.864-.716.463.463 0 0 1-.2-.626.468.468 0 0 1 .63-.2 7.749 7.749 0 0 0 8.368-1.303 7.62 7.62 0 0 0 .525-10.816C12.314.941 7.439.708 4.287 3.55a7.622 7.622 0 0 0-.526 10.816v-1.009c0-.257.21-.465.469-.465.258 0 .468.208.468.465v2.092c0 .258-.21.466-.468.466H2.123a.466.466 0 1 1 0-.931h.953a8.582 8.582 0 0 1-2.15-4.567C.26 5.712 3.559 1.361 8.296.699c4.735-.66 9.112 2.616 9.779 7.319.665 4.701-2.628 9.048-7.359 9.715z"
                                              />
                                            </svg>
                                            <div
                                              className={
                                                this.props.cssfile
                                                  .hotel_autocomplete_name
                                              }
                                            >
                                              <div
                                                className={
                                                  this.props.cssfile
                                                    .hotel_autocomplete_city
                                                }
                                              >
                                                {el.searchby}
                                              </div>
                                              {/* <div className={this.props.cssfile.hotel_autocomplete_tag}>800 Properties</div> */}
                                            </div>
                                            <div
                                              className={
                                                this.props.cssfile.hotelnw_city
                                              }
                                            ></div>
                                          </div>
                                        );
                                      }
                                    )}
                                  </div>
                                )}

                                {/* <div className={this.props.cssfile.pop_tabs}>
                                  <p className={this.props.cssfile.suggestions_new}>
                                  Suggestions / Popular Destination
                                  </p>
                                    <ul className={this.props.cssfile.pop_tabs_list}>
                                      <li>Dubai</li>
                                      <li>Singapore</li>
                                      <li>Bali</li>
                                      <li>Goa</li>
                                      <li>Kashmir</li>
                                      <li>Malaysia</li>
                                      <li>Malaysia</li>
                                    </ul>
                                  </div> */}

                                {/* <button className={ this.props.cssfile.applyNow}>Apply Now</button> */}
                              </div>
                            )}
                            {this.state.noresults && (
                              <div className={this.props.cssfile.dropdown_main}>
                                <p className={this.props.cssfile.suggestions}>
                                  Suggestions
                                </p>
                                <div
                                  className={
                                    this.props.cssfile.mainactivitydrop
                                  }
                                >
                                  <div
                                    key={`noresults`}
                                    className={
                                      this.props.cssfile
                                        .activity_autocomplete_element
                                    }
                                  >
                                    {/* <img src="images/location-01.svg" /> */}

                                    {/* <span></span> */}
                                    <div>
                                      <div
                                        className={
                                          this.props.cssfile
                                            .activity_autocomplete_name
                                        }
                                        onClick={() => {
                                          this.setState({ activityName: "" });
                                        }}
                                      >
                                        No Results Found
                                      </div>
                                    </div>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="26"
                                      height="26"
                                    >
                                      <path
                                        fill-rule="evenodd"
                                        stroke="#000"
                                        fill="#FFF"
                                        d="m14.92 4.816.167-.913a.349.349 0 0 0-.686-.124v-.001l-.167.912A9.709 9.709 0 0 0 6.89 6.557a9.202 9.202 0 0 0-3.389 5.145.345.345 0 0 0 .177.389.35.35 0 0 0 .423-.077 2.759 2.759 0 0 1 2.567-.873l.063-.341-.063.341a2.746 2.746 0 0 1 2.187 2.008.348.348 0 0 0 .624.113 3.774 3.774 0 0 1 3.474-1.577l-.328 1.79-1.316 7.18c-1.594.009-3.072.126-4.296.324-.638.102-1.208.226-1.694.37s-.888.304-1.198.496a.346.346 0 0 0-.113.477.349.349 0 0 0 .48.112c.227-.14.581-.288 1.029-.42 1.349-.399 3.536-.667 5.992-.666 1.638 0 3.155.118 4.385.316.615.098 1.158.218 1.607.35.448.132.802.28 1.029.421a.348.348 0 1 0 .368-.59c-.311-.191-.712-.352-1.199-.496-1.356-.399-3.381-.656-5.681-.69l1.293-7.059.327-1.785c1.34.376 2.331 1.426 2.647 2.682a.347.347 0 0 0 .275.257.353.353 0 0 0 .35-.143 2.743 2.743 0 0 1 2.757-1.111c.997.18 1.766.86 2.093 1.717a.35.35 0 0 0 .672-.081 9.15 9.15 0 0 0 .069-1.117c-.001-4.344-3.114-8.222-7.581-9.203z"
                                      />
                                    </svg>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                          {/* // ) : null} */}
                        </ModalPopupComponent>
                      </div>
                    </div>
                  </div>
                  <div className={this.props.cssfile.holiday_Dropcont}>
                    <div className={this.props.cssfile.catg_lblCont}>
                      <label>Category (Optional)</label>
                    </div>

                    <div
                      className={this.props.cssfile.catg_lblInput}
                      ref={this.categoryRef}
                    >
                      <span className={this.props.cssfile.drop_arrow}>
                        <svg
                          version="1.1"
                          id="Layer_1"
                          x="0px"
                          y="0px"
                          width="200px"
                          height="200px"
                          viewBox="0 0 200 200"
                          className={
                            this.state.isCategoryVisible
                              ? this.props.cssfile.dropdownicon
                              : ""
                          }
                          onClick={() => {
                            this.setState({
                              isCategoryVisible: !this.state.isCategoryVisible,
                            });
                          }}
                        >
                          <defs></defs>
                          <g transform="matrix(1, 0, 0, 1, 5.362504, 3.483317)">
                            <path
                              fill="#266BAC"
                              d="M 137.761 105.745 L 79.925 163.779 C 76.558 166.948 71.407 166.948 68.238 163.779 C 65.07 160.61 65.07 155.262 68.238 152.092 L 120.33 100 L 68.238 47.909 C 65.07 44.542 65.07 39.392 68.238 36.223 C 71.407 33.054 76.557 33.054 79.925 36.223 L 137.761 94.059 C 140.93 97.426 140.93 102.576 137.761 105.745 Z"
                              style={{
                                transformBox: "fill-box",
                                transformOrigin: "50% 50%",
                                transform: "matrix(0, 1, -1, 0, 0.000003, 0)",
                              }}
                            ></path>
                          </g>
                        </svg>
                      </span>
                      <input
                        // autoFocus
                        type="text"
                        placeholder="Select Categories"
                        value={this.state.categoryValue.map((ele) => {
                          return ele.name ? ` ${ele.name}` : "";
                        })}
                        onClick={() => {
                          this.setState({
                            isCategoryVisible: true,
                            categorySearchValue: "",
                          });
                          document.querySelector("body").style.overflow =
                            "hidden";
                        }}
                      />
                      <ModalPopupComponent
                        isPopup={this.state.isCategoryVisible}
                        modalCss={this.props.popupcss}
                        closePopup={this.closeCategoryPopup}
                        showBtn={true}
                        heading={"Category (Optional)"}
                      >
                        <div
                          className={
                            this.props.cssfile.activity_autocomplete_value
                          }
                          id="ACTIVITY-AUTO-SUGGEST-DATA-LIST"
                        >
                          <div
                            className={
                              this.props.cssfile.input_search_destination
                            }
                          >
                            <svg
                              class={this.props.cssfile.theme5_search_icon}
                              xmlns="http://www.w3.org/2000/svg"
                              width="15"
                              height="14"
                            >
                              <defs>
                                <filter id="vz9xq22j6a">
                                  <feFlood
                                    flood-color="#979797"
                                    flood-opacity="1"
                                    result="floodOut"
                                  ></feFlood>
                                  <feComposite
                                    operator="atop"
                                    in="floodOut"
                                    in2="SourceGraphic"
                                    result="compOut"
                                  ></feComposite>
                                  <feBlend
                                    in="compOut"
                                    in2="SourceGraphic"
                                  ></feBlend>
                                </filter>
                              </defs>
                              <g filter="url(#vz9xq22j6a)">
                                <path
                                  fill-rule="evenodd"
                                  fill="#97144D"
                                  d="M10.928 9.513a6.02 6.02 0 1 0-9.812-6.982 6.02 6.02 0 0 0 8.397 8.397l2.83 2.83a1.003 1.003 0 0 0 1.415-1.415l-2.83-2.83zM2.006 6.031a4.015 4.015 0 1 1 6.855 2.84 4.015 4.015 0 0 1-6.855-2.84z"
                                ></path>
                              </g>
                            </svg>
                            <input
                              autoFocus
                              type="text"
                              tabIndex="1"
                              autoComplete="off"
                              id="myTextField"
                              placeholder="Select Categories"
                              value={this.state.categorySearchValue}
                              onChange={this.onChangeCategorySearch}
                            />
                          </div>
                          <div class={this.props.cssfile.dropdown_main}></div>
                        </div>

                        <div className={this.props.cssfile.dropDownlistmain}>
                          <ul
                            className={
                              this.props.cssfile.dropdown_value_container
                            }
                          >
                            {this?.state?.categoryList
                              ?.filter((item) =>
                                item.name
                                  .toLowerCase()
                                  .includes(
                                    this.state.categorySearchValue.toLowerCase()
                                  )
                              )
                              .map((ele, id) => {
                                return (
                                  <li key={"category" + id}>
                                    <label
                                      className={
                                        this.props.cssfile.checkbox_label
                                      }
                                    >
                                      <span
                                        className={
                                          this.props.cssfile.bagCheckbox
                                        }
                                      >
                                        <input
                                          type="checkbox"
                                          checked={this.categorySelected(ele)}
                                          className={this.props.cssfile.pointer}
                                          onClick={(e) =>
                                            this.categoryHandler(e, ele)
                                          }
                                          autoFocus
                                        />
                                        <span
                                          className={this.props.cssfile.bagBox}
                                        >
                                          <span
                                            className={
                                              this.props.cssfile.bagCheck
                                            }
                                          ></span>
                                        </span>
                                      </span>
                                      <span
                                        className={this.props.cssfile.advTitle}
                                      >
                                        {ele.name}
                                      </span>
                                    </label>
                                  </li>
                                );
                              })}
                          </ul>
                        </div>
                      </ModalPopupComponent>
                      {/* <svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 200 200" class=""><defs></defs><g transform="matrix(1, 0, 0, 1, 5.362504, 3.483317)"><path fill="#266BAC" d="M 137.761 105.745 L 79.925 163.779 C 76.558 166.948 71.407 166.948 68.238 163.779 C 65.07 160.61 65.07 155.262 68.238 152.092 L 120.33 100 L 68.238 47.909 C 65.07 44.542 65.07 39.392 68.238 36.223 C 71.407 33.054 76.557 33.054 79.925 36.223 L 137.761 94.059 C 140.93 97.426 140.93 102.576 137.761 105.745 Z" style="transform-box: fill-box; transform-origin: 50% 50%; transform: matrix(0, 1, -1, 0, 3e-06, 0);"></path></g></svg> */}
                    </div>
                  </div>
                  <div
                    className={`${this.props.cssfile.holiday_Dropcont} ${this.props.cssfile.holiday_Dropcont_last}`}
                  >
                    <div className={this.props.cssfile.catg_lblCont}>
                      <label>Month of Travel (Optional)</label>
                    </div>

                    <div
                      className={this.props.cssfile.catg_lblInput}
                      // ref={this.monthRef}
                    >
                      <span className={this.props.cssfile.drop_arrow}>
                        <svg
                          version="1.1"
                          id="Layer_1"
                          x="0px"
                          y="0px"
                          width="200px"
                          height="200px"
                          viewBox="0 0 200 200"
                          className={
                            this.state.isMonthVisible
                              ? this.props.cssfile.dropdownicon
                              : ""
                          }
                        >
                          <defs></defs>
                          <g transform="matrix(1, 0, 0, 1, 5.362504, 3.483317)">
                            <path
                              fill="#266BAC"
                              d="M 137.761 105.745 L 79.925 163.779 C 76.558 166.948 71.407 166.948 68.238 163.779 C 65.07 160.61 65.07 155.262 68.238 152.092 L 120.33 100 L 68.238 47.909 C 65.07 44.542 65.07 39.392 68.238 36.223 C 71.407 33.054 76.557 33.054 79.925 36.223 L 137.761 94.059 C 140.93 97.426 140.93 102.576 137.761 105.745 Z"
                              style={{
                                transformBox: "fill-box",
                                transformOrigin: "50% 50%",
                                transform: "matrix(0, 1, -1, 0, 0.000003, 0)",
                              }}
                            ></path>
                          </g>
                        </svg>
                      </span>
                      <input
                        type="text"
                        placeholder="Select Month"
                        value={this.state.monthValue}
                        onClick={() => {
                          this.setState({
                            monthValue: "",
                            isMonthVisible: true,
                            monthSearchValue: "",
                          });
                          document.querySelector("body").style.overflow =
                            "hidden";
                        }}
                      />
                      <ModalPopupComponent
                        isPopup={this.state.isMonthVisible}
                        modalCss={this.props.popupcss}
                        closePopup={this.closeMonthPopup}
                        showBtn={true}
                        heading={"Month of Travel (Optional)"}
                      >
                        <div
                          className={
                            this.props.cssfile.activity_autocomplete_value
                          }
                          id="ACTIVITY-AUTO-SUGGEST-DATA-LIST"
                        >
                          <div
                            className={
                              this.props.cssfile.input_search_destination
                            }
                          >
                            <svg
                              class="theme5_search_icon__Jt1_S"
                              xmlns="http://www.w3.org/2000/svg"
                              width="15"
                              height="14"
                            >
                              <defs>
                                <filter id="vz9xq22j6a">
                                  <feFlood
                                    flood-color="#979797"
                                    flood-opacity="1"
                                    result="floodOut"
                                  ></feFlood>
                                  <feComposite
                                    operator="atop"
                                    in="floodOut"
                                    in2="SourceGraphic"
                                    result="compOut"
                                  ></feComposite>
                                  <feBlend
                                    in="compOut"
                                    in2="SourceGraphic"
                                  ></feBlend>
                                </filter>
                              </defs>
                              <g filter="url(#vz9xq22j6a)">
                                <path
                                  fill-rule="evenodd"
                                  fill="#97144D"
                                  d="M10.928 9.513a6.02 6.02 0 1 0-9.812-6.982 6.02 6.02 0 0 0 8.397 8.397l2.83 2.83a1.003 1.003 0 0 0 1.415-1.415l-2.83-2.83zM2.006 6.031a4.015 4.015 0 1 1 6.855 2.84 4.015 4.015 0 0 1-6.855-2.84z"
                                ></path>
                              </g>
                            </svg>
                            <input
                              type="text"
                              tabIndex="1"
                              autoComplete="off"
                              id="myTextField"
                              placeholder="Select Month"
                              value={this.state.monthSearchValue}
                              onChange={this.onChangeMonthSearch}
                              autoFocus
                            />
                          </div>
                          <div class={this.props.cssfile.dropdown_main}></div>
                        </div>
                        <div
                          className={this.props.cssfile.dropDownlistmainMonth}
                        >
                          <ul
                            className={
                              this.props.cssfile.dropdown_value_container
                            }
                          >
                            {this.state.monthData
                              .filter((item) =>
                                item.month
                                  .toLowerCase()
                                  .includes(
                                    this.state.monthSearchValue.toLowerCase()
                                  )
                              )
                              .map((ele, id) => {
                                return (
                                  <li key={"month" + id}>
                                    <label
                                      className={`${this.props.cssfile.checkbox_label} ${this.props.cssfile.option_month}`}
                                    >
                                      {/* <span
                                      className={this.props.cssfile.bagCheckbox}
                                    >
                                      <input
                                        type="checkbox"
                                        checked={this.categorySelected(ele)}
                                        className={this.props.cssfile.pointer}
                                        
                                        onClick={(e) =>
                                          this.categoryHandler(e, ele)
                                        }
                                      />
                                      <span className={this.props.cssfile.bagBox}>
                                        <span
                                          className={this.props.cssfile.bagCheck}
                                        ></span>
                                      </span>
                                    </span> */}
                                      <span
                                        className={
                                          this.props.cssfile.advTitleDate
                                        }
                                        onClick={() => {
                                          this.setState({
                                            monthValue: ele.month,
                                            // isMonthVisible: false,
                                          });
                                        }}
                                      >
                                        {ele.month}
                                      </span>
                                    </label>
                                  </li>
                                );
                              })}
                          </ul>
                        </div>
                      </ModalPopupComponent>
                    </div>
                  </div>
                  {/* <div
                    className={`${this.props.cssfile.activity_search_column} ${this.props.cssfile.activity_search_date}`}
                  >
                    <DesktopCalender
                      tabIndex={2}
                      label="Date"
                      styles={this.props.calendarStyles}
                      value={
                        !cf.isValueEmpty(this.state.checkInOutValue)
                          ? this.state.checkInOutValue
                          : ""
                      }
                      placholder="Select the date"
                      onChangeDate={(dateVal) => {
                        this.onChangeCheckInOutValue(dateVal);
                      }}
                      // iconImage={<img src="images/calendar-01.svg" />}
                      startDate={cf.getDateWithFormat(
                        new Date(),
                        this?.props?.dateFormat
                      )}
                      startDateLimit={cf.getDateWithFormat(
                        new Date(),
                        this?.props?.dateFormat
                      )}
                      dateFormat={this?.props?.dateFormat}
                      changeVisibility={(bool) => {
                        this.scrollToMyRef();
                        this.setState({
                          isCalendarVisible: bool,
                          isAutoSuggestVisible: false,
                          isDateError: false,
                        });
                      }}
                      isVisible={this.state.isCalendarVisible}
                      doubleSelection={false}
                    />
                    <span>
                      <small></small>
                    </span>
                    {this.state.isDateError && (
                      <div className={this.props.cssfile.errordate}>
                        {"Please select Date"}
                      </div>
                    )}
                  </div> */}

                  {/* <div
                    className={`${this.props.cssfile.activity_search_details_row} ${this.props.cssfile.activity_nw}`}
                  > */}
                  <div className={this.props.cssfile.activity_search_class}>
                    <button
                      tabIndex={3}
                      className={
                        this.state?.btnClicked
                          ? `${this.props.cssfile.activity_search_widget_btn} loading`
                          : this.props.cssfile.activity_search_widget_btn
                      }
                      // className={this.props.cssfile.activity_search_widget_btn}
                      onClick={(e) => {
                        e.stopPropagation();
                        this.onClickSearchActivity();
                      }}
                      onFocus={(e) => {
                        e.stopPropagation();
                        this.onInputFocus("search");
                      }}
                    >
                      {/* <svg width="50px" height="50px" viewBox="0,0,256,256">
                        <g fill="#ffffff" fillRule="nonzero" stroke="none" strokeWidth="1" strokeLinecap="butt" strokeLinejoin="miter" strokeMiterlimit="10" strokeDasharray="" strokeDashoffset="0" fontFamily="none" fontWeight="none" fontSize="none" textAnchor="none">
                        <g transform="scale(5.12,5.12)"><path d="M21,3c-9.39844,0 -17,7.60156 -17,17c0,9.39844 7.60156,17 17,17c3.35547,0 6.46094,-0.98437 9.09375,-2.65625l12.28125,12.28125l4.25,-4.25l-12.125,-12.09375c2.17969,-2.85937 3.5,-6.40234 3.5,-10.28125c0,-9.39844 -7.60156,-17 -17,-17zM21,7c7.19922,0 13,5.80078 13,13c0,7.19922 -5.80078,13 -13,13c-7.19922,0 -13,-5.80078 -13,-13c0,-7.19922 5.80078,-13 13,-13z"></path>
                        </g></g>
                      </svg> */}
                      Search
                    </button>
                  </div>
                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
